import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { DispatchService } from "../../dispatchService";
import RouteEntity from "../../entity/RouteEntity";
import { environment } from "@env/environment";
import { Utils } from "@services/utils";
import { UIHelper } from "@services/UIHelper";
import { ResponseAdminGetClientDispatchNote } from "@wearewarp/types-server-admin/client";
import { FormDataUpdateText, ModalHelper, UpdateText } from "@wearewarp/ng-antd";
import { DataRepoClient } from "@app/data-repo/client";

interface DisplayContactInfoItem {
  label: string,
  value: string,
}
interface DisplayCustomer {
  id: string,
  name: string,
  contactInfos: DisplayContactInfoItem[],
  trackingUrl: string,
  showNote?: boolean,
  note?: ResponseAdminGetClientDispatchNote,
}

@Component({
  selector: "[dispatch-customer]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class DispatchCustomerInfo extends BaseComponent {
  listData: DisplayCustomer[] = [];
  isLoading = false;
  constructor(
    private dispatchService: DispatchService,
    private modalHelper: ModalHelper,
    private dataRepoClient: DataRepoClient,
  ) {
    super();
  }
  private route: RouteEntity;
  ngOnInit() {
    super.ngOnInit();
    this.subscription.add(
      this.dispatchService.routeData$.subscribe(() => {
        this.route = this.dispatchService.getRoute();
        this.fetchCustomerInfo();
      })
    );
  }

  private getPersonName(person) {
    if (!person) return "N/A";
    if (person?.firstName == "N/A" && person?.lastName == "N/A") return "N/A";
    if (person?.firstName || person?.lastName) {
      return `${person?.firstName == "N/A" ? "" : person?.firstName} ${person?.lastName == "N/A" ? "" : person?.lastName}`.trim();
    }
    if (person?.fullName) {
      return person?.fullName;
    }
    return "N/A";
  }

  private getContact(customer) {
    return customer ? this.getPersonName(customer.primaryContact) : "N/A";
  }

  private getSeller(customer) {
    return customer ? this.getPersonName(customer.seller) : "N/A";
  }

  private getAccountOwner(customer) {
    return customer ? this.getPersonName(customer.accountOwner) : "N/A";
  }

  private getCustomerServiceRep(customer) {
    return customer ? this.getPersonName(customer.customerServiceRep) : "N/A";
  }

  private getPhone(customer: any) {
    if (!customer) return "N/A";
    if (customer.primaryContact?.phone) {
      return this.displayPhone(customer.primaryContact?.phone);
    }
    return "N/A";
  }
  private getEmail(customer: any) {
    if (!customer) return "N/A";
    if (customer.primaryContact?.email) {
      return customer.primaryContact?.email;
    }
    return "N/A";
  }
  private fetchCustomerInfo() {
    this.isLoading = true;
    const url = `${Const.APIV2(Const.APIURI_JOBS)}/${this.route.getId()}/customer-contacts`;
    this.api.GET(url).subscribe((res: any) => {
      this.buildDisplayData(res.data?.list_data ?? []);
      this.isLoading = false;
    }, err => {
      UIHelper.showErr(err);
    });
  }

  private buildDisplayData(customers: any[]) {
    let jobCode = this.route.getCode()
    this.listData = [];
    for (let customer of customers) {
      const item: DisplayCustomer = {
        id: customer.id,
        name: customer.name,
        contactInfos: [
          {label: 'Contact Name', value: this.getContact(customer)},
          {label: 'Phone Number', value: this.getPhone(customer)},
          {label: 'Email', value: this.getEmail(customer)},
          {label: 'Seller', value: this.getSeller(customer)},
          {label: 'Account Owner', value: this.getAccountOwner(customer)},
          {label: 'Customer Service Rep', value: this.getCustomerServiceRep(customer)},
        ],
        trackingUrl: `${environment.trackingWebUrl}/${jobCode}/${customer.id}`,
      }
      this.listData.push(item);
    }
  }

  copyTrackingLink(customer: DisplayCustomer) {
    if(!customer.trackingUrl) return;
    Utils.copyTextToClipboard(customer.trackingUrl, e => {
      if (e) {
        this.showErr('Cannot copy public link.');
      } else {
        this.showSuccess(`Public link was copied successfully`);
      }
    });
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  showCustomerNotes(customer: DisplayCustomer) {
    this.modalHelper.openForm<FormDataUpdateText, UpdateText>(UpdateText, {
      nzTitle: `Update notes for customer <b>${customer.name}</b>`,
      nzComponentParams: {
        multiline: true,
        minRows: 4,
        maxRows: 10,
        placeHolder: 'Enter notes',
        model: {text: customer.note?.content},
        submit: data => this.updateNotes(customer, data.text),
      },
      onSubmitSucceeded: (resp) => {
        customer.note = resp;
        customer.showNote = customer.note.content.length > 0;
      },
      onSubmitError: err => UIHelper.showErr(err),
    })
  }

  private updateNotes(customer: DisplayCustomer, content: string) {
    return this.dataRepoClient.setDispatchNote(customer.id, content);
  }

  onCustomerNotesFetchingDone(customer: DisplayCustomer, result: ResponseAdminGetClientDispatchNote) {
    customer.note = result;
    customer.showNote = customer.note.content.length > 0;
  }
}
