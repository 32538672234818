<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="!isLoading && !isHasTask" class="nodata">No Data</div>

<div *ngIf="!isLoading && isHasTask">
  <div *ngFor="let task of tasks">
    <div *ngIf="isTaskConfirmBooking(task)">
      <div class="task-header">
        <div class="task-title">Confirm Booking</div>
        <div class="confirm-booking-actions">
          <div class="items-confirmed" [ngClass]="confirmItemStatus">
            {{ displayInfo.itemsConfirmed }}
          </div>
          <div class="action-item" (click)="onBtnOpenChecklistDialog(task)">View checklist</div>
          <div class="action-item" *ngIf="isAssignedCarrier" (click)="onBtnOpenSendEmailDialog(task)">Resend email</div>
        </div>
      </div>
      <div class="task-content top10" *ngIf="isAssignedCarrier">
        <!-- wonbid email, default sent by system -->
         <div *ngIf="emailWonBidSentBy" class="small-text italic-text flex">
          <div class="right5">Email "You won the bid" Sent</div>
          <warp-when-by [model]="emailWonBidSentBy" [viewTemplate]="tplSendWonBidEmail"></warp-when-by>
          <ng-template #tplSendWonBidEmail let-displayInfo="displayInfo">
            <div>{{ displayInfo.createdWhen }} by WARP System</div>
          </ng-template>
         </div>
        <div *ngFor="let item of logSendConfirmBookingEmail; let i = index">
          <div class="small-text italic-text flex">
            <div class="right5">Email "Confirm Booking" {{ i == 0 ? 'Sent' : 'Resent'}}</div>
            <warp-when-by [model]="item?.insert" [viewTemplate]="tplSendConfirmBookingEmail"></warp-when-by>
            <ng-template #tplSendConfirmBookingEmail let-displayInfo="displayInfo">
              <div>{{ displayInfo.createdWhen }} by {{ displayInfo.createdBy }}</div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="g-driver">
        <div class="d-title">Assign Driver</div>
        <div class="g-content">
          <div class="d-label">{{ driverContent.text }}</div>
          <div class="d-action">
            <ng-container *ngIf="driverContent.status === 'confirmed'">
              <nz-tag [nzColor]="'green'">DONE</nz-tag>
            </ng-container>

            <ng-container *ngIf="driverContent.status === 'pending'">
              <nz-tag [nzColor]="'orange'">NOT DONE</nz-tag>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>