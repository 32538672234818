import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';

@Component({
  selector: 'app-email-bol-client',
  templateUrl: './email-bol-client.component.html',
  styleUrls: ['./email-bol-client.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmailBolClientComponent extends BaseFormDialog1 implements OnInit {
  protected formGroupDeclaration: FormGroupDeclaration = {
    customers: {
      label: '',
      type: 'formArray', childItem: {
        id: { label: '', type: 'string' },
        name: { label: 'Name', type: 'string' },
        email: { label: 'Email', type: 'string' },
        isSend: { label: '', type: 'boolean', initialValue: false }
      },
      
    },
    remark: { label: "Remark" },
  };

  @Input() jobId;
  @Input() clients;
  public isError = false;
  public isLoading = false;
  public confirmData: any = {};
  public inConfirm: boolean = false;
  constructor() { 
    super();
  }

  ngOnInit(): void {
    this.getData();
  }

  getFormItemValue(key: string) {
    return super.getFormItemValue(key)
  }

  getFormArrayKey(index, key) {
    return `customers[${index}].${key}`
  }

  getData() {
    this.createFormInput();

    //lấy luôn dữ liệu từ job để đẩy vào form, không cần fetch lại API
    let clients = this.clients || [];
    let clientEmails = [];
    clients.map((client) => {
      let contact = client.primaryContact || {};
      clientEmails.push({ id: client.id, email: contact.email, name: client.name});
    });

    //luôn set giá trị cho array customerEmails để tránh null
    this.setItemValue("customers", clientEmails);
    console.log(this.getFormData_JSON(true))
    if (clientEmails.length > 0) {
      for(let index in clientEmails) {
        this.getItemByKey(`customers[${index}].email`).disable();
      }
    }

  }

  onEditEmail(index, formControlName) {
    let key = `customers[${index}].${formControlName}`
    //khi click btn edit trên form (cạnh input) thì enable để user có thể edit input.
    if (this.getItemByKey(key).enabled)
      this.getItemByKey(key).disable();
    else this.getItemByKey(key).enable();
  }

  get canClickOK() {
    let data: any = this.getFormData_JSON(true);
    let customers = data.customers || [];
    for(let item of customers) {
      if(item.isSend && !item.email) return false;
    }
    let count = customers.filter(it => it.isSend).length;

    return count > 0;
  }

  onBtnSave() {
    let json: any = this.getFormData_JSON(true);
    //ở lần ấn đầu, hiển thị form confirm thay vì submit luôn.
    if (!this.inConfirm) {
      this.confirmData = json;
      this.confirmData.customers = json.customers.filter(it => it.isSend)
      this.inConfirm = true;
      return;
    }

    //lần ấn tiếp theo (confirm) thì call API
    let contacts = json.customers.filter(it => it.isSend);;
    if(!contacts.length) {
      return;
    }

    const data = {
      contacts: contacts,
      remark: json.remark,
      jobId: this.jobId,
    };

    this.startProgress();
    this.isLoading = true;
    this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/send-bol-to-customers`, data).subscribe(
      (resp) => {
        this.stopProgress();
        this.isLoading = false;
        this.closeDialog();
        this.showSuccess(resp.message);
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.isLoading = false;
        this.closeDialog();
      }
    );
  }

  onBtnCancel() {
    //back to form
    if (this.inConfirm) {
      this.inConfirm = false;
      return;
    }
    //close dialog
    this.closeDialog();
  }

}
