import to from 'await-to-js';
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DataRepoClient } from "@app/data-repo/client";
import { ResponseAdminGetClientDispatchNote } from "@wearewarp/types-server-admin/client";
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';
import { getLinkDashboard } from '@services/routerlink';

@Component({
  selector: "[dispatch-customer-note]",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss"],
})
export class DispatchCustomerNote implements OnInit {
  @Input() customerId: string;
  @Input() data: ResponseAdminGetClientDispatchNote;
  @Output() fetchDataDone = new EventEmitter<ResponseAdminGetClientDispatchNote>();

  constructor(private dataRepo: DataRepoClient) {
  }

  ngOnInit(): void {
    this.fetchData();
  }

  reload() {
    this.fetchData();
  }

  private async fetchData() {
    const [err, data] = await to(this.dataRepo.getDispatchNote(this.customerId));
    if (err) {
      console.error(err);
    }
    if (data) {
      this.data = data;
    }
    this.fetchDataDone.emit(data);
  }

  get updateWhen() {
    return DateUtil.format(this.data.update.when, Const.FORMAT_GUI_DATETIME);
  }

  get hyperLinkUpdateBy() {
    return getLinkDashboard(this.data.update.by);
  }
}