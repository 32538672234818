import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { RouteService } from "../../services/route.service";
import RouteEntity from "../../entities/RouteEntity";
import { Subscription } from "rxjs";
import TaskEntity from "../../entities/TaskEntity";
import { Const } from "@wearewarp/universal-libs";
import { MapService } from "../../services/map.service";
import { Utils } from "@services/utils";
import { BaseComponent } from "@abstract/BaseComponent";
import { SortedTaskByShipmentId } from "@wearewarp/types-server-admin";
import ShipmentEntity from "../../entities/ShipmentEntity";
import { DialogService } from "@dialogs/dialog.service";
import { ValidateShipmentModal } from "../validate-shipment-modal";
@Component({
  selector: 'adjust-route-shipment',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class AdjustRouteShipment extends BaseComponent {
  private _route: RouteEntity;

  public isLoading: boolean = true;
  public isSubmitting: boolean = false;
  public step = 0;
  public shipments: ShipmentEntity[]

  constructor(
    private routeService: RouteService
  ) {
    super()
  }

  @Input() set route(route) {
    this._route = route;
    this.shipments = this._route?.getShipments()
  }
  get route() {
    return this._route
  }

  ngOnInit(): void {
    this.subscription.add(
      this.routeService.loading.subscribe(value => {
        this.isLoading = value;
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  async onSelectNewShipment(shipments: ShipmentEntity[]) {
    this.shipments = [...shipments, ...this.shipments];
    this.route.updateShipments(this.shipments);
    await this.routeService.fetchItemsForAddedShipments(shipments.map(s => s.getId()));
    //add task để phục vụ sorting
    shipments.forEach(shipment => {
      this.route
        .addTask(new TaskEntity().initByShipment(shipment, Const.TaskType.PICKUP))
        .addTask(new TaskEntity().initByShipment(shipment, Const.TaskType.DROPOFF))
    });
    this.route.updateTasks(this.route.getTasks())
    this.shouldShowBtnAddShipment = false;
    this.warpIdsInput = null;
    this.showSuccess("Added shipment to route.");
  }

  onRemoveShipment(shipment: ShipmentEntity) {
    this.shipments = this.shipments.filter(s => s.getId() != shipment.getId());
    this.route
      .updateShipments(this.shipments)
      .removeTaskByShipmentId(shipment.getId())
      .updateTasks(this.route.getTasks())
  }

  shouldShowBtnAddShipment = false;
  warpIdsInput : any;
  splitWarpIdsInput(event: any){
    if(event.keyCode == 13) {
      let handledInput = [];
      let currentInput = this.warpIdsInput;
      for(let i=0;i<currentInput.length;i++){
        if(currentInput[i].includes(',') || currentInput[i].includes(' ')){
          let tmp = currentInput[i].split(/[\s,]+/);
          handledInput.push(...tmp);
        }else{
          handledInput.push(currentInput[i]);
        }
      }
      this.warpIdsInput = handledInput;
    }
  }

  onChangeWarpIdsInput(event) {
    if(event?.length == 0 || !event) {
      this.shouldShowBtnAddShipment = false;
    } else {
      this.shouldShowBtnAddShipment = true;
    }
  }

  onBtnAddShipment() {
    DialogService.openFormDialog1(ValidateShipmentModal, {
      nzClassName: 'modal modal-xl',
      nzComponentParams: {
        listWarpIds: this.warpIdsInput,
        listShipemnts: this.shipments,
        routeEntity: this._route,
        listShipmentSelected: (shipments: ShipmentEntity[]) => {
          this.onSelectNewShipment(shipments);
        }
      }
    })
  }
}
