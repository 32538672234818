<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="!isLoading">
  <div *ngIf="!displayInfo.carrier?.name">
    <div style="display: flex; justify-content: center;">
      <nz-empty [nzNotFoundContent]="'No Carrier Assigned'"></nz-empty>
    </div>
    <!-- <div style="display: flex; justify-content: center;">
      <button nz-button class="btn-assign-carrier" (click)="onBtnAssignCarrier()">Assign Carrier & Driver</button>
    </div> -->
    <div *ngIf="displayInfo.basePrice">
      <div class="separator h top15 bottom15"></div>
      Base Price: ${{displayInfo.basePrice}}
      <div class="separator h top15 bottom15"></div>
    </div>
    <div *ngIf="!isExternalRoute" class="top20" style="display: flex; justify-content: center;">
      <button *ngIf="!displayInfo.carrierBidId" (click)="onClickCreateCarrierBid()" nz-button
        class="btn-carrier-bid">Start Carrier Bid</button>
      <button *ngIf="displayInfo.carrierBidId" (click)="onClickViewCarrierBid()" nz-button class="btn-carrier-bid">Go to
        Carrier Bid</button>
    </div>
    <div *ngIf="isExternalRoute" class="top20" style="display: flex; justify-content: center;">
      <button (click)="onBtnAssignCarrierForExternalRoute()" nz-button class="btn-carrier-bid">Assign Carrier</button>
    </div>
  </div>
  <div *ngIf="displayInfo.carrier?.name">
    <div class="flex">
      <div class="flex1">
        <div>
          <a [routerLink]="[this.routeAdminCarrierList, displayInfo.carrier?.id]" target="_blank">
            <span class="carrier-name">{{displayInfo.carrier?.name}}</span>
          </a>
          <i *ngIf="mcOrDot" class="view-mc-dot">
            (<span>{{mcOrDot.label}}</span>: <span #elemMc nz-tooltip nzTooltipTitle="Copy {{mcOrDot.label}}" class="clickable" (click)="copyElementText(elemMc)">{{mcOrDot.value}}</span>)
          </i>
          <nz-tag *ngIf="displayInfo.isFirstAssigned" style="margin-left: 8px; margin-right: 0;" nzColor="purple">Rookie</nz-tag>
          <i nz-icon nzType="copy" nzTheme="outline" class="clickable left10"
            nz-tooltip nzTooltipTitle="Copy carrier name"
            (click)="copyText(displayInfo.carrier?.name, 'Carrier name')"></i>
          <button *ngIf="showUnassignCarrier" nz-button nzType="text" class="left10" nzDanger
            (click)="onBtnUnassignCarrier()">Unassign</button>
        </div>
        <div *ngFor="let item of displayInfo.carrier?.contacts" class="contact-item">
          <span>{{item.fullName}}</span>
          <span class="left10 medium" style="display: block;">
            <contact-info owner="{{item.phoneNumber}}" type="phone" [scopes]="['*']" style="display: inline-block;">
              {{item.phoneNumber}}
            </contact-info>
            <i nz-icon nzType="copy" nzTheme="outline" class="clickable left10"
              nz-tooltip nzTooltipTitle="Copy contact phone"
              (click)="copyText(item.phoneNumber, 'Carrier phone number')"></i>
          </span>
          <span class="left10 medium">
            {{item.email}}
            <i nz-icon nzType="copy" nzTheme="outline" class="clickable left10"
              nz-tooltip nzTooltipTitle="Copy contact email"
              (click)="copyText(item.email, 'Carrier email')"></i>

          </span>
        </div>
      </div>
      <div class="flex1">
        <div class="contact-item">
          <span>Carrier sales rep:</span>
          <span class="left10 medium">{{ this.carrierSalesRep || 'N/A' }}</span>
          <button class="btn-update" nz-button nzType="link" nzSize="small"
            (click)="onBtnUpdateCarrierSalesRep()">Update</button>
        </div>
      </div>
    </div>

    <div class="top10">
      <div class="label">{{ isShowSecondaryDriver ? 'Primary Driver' : 'Driver'}}</div>
      <div class="flex">
        <div *ngIf="!displayInfo.driver?.fullName">
          No Driver Assigned
        </div>
        <div *ngIf="displayInfo.driver?.fullName">
          <a [routerLink]="[this.routeAdminDriverList, displayInfo.driver?.id]"
            target="_blank"><span>{{displayInfo.driver?.fullName}}</span></a>

          <span class="left10 medium">
            <contact-info owner={{displayInfo.driver?.phoneNumber}} type="phone" inline="true"
              [scopes]="['notification']"></contact-info>
              <i nz-icon nzType="copy" nzTheme="outline" class="clickable left10"
              nz-tooltip nzTooltipTitle="Copy driver's phone"
              (click)="copyText(displayInfo.driver?.phoneNumber, 'Driver phone number')"></i>
          </span>
          <span class="left10 medium">
            <contact-info owner={{displayInfo.driver?.email}} type="email" inline="true"
              [scopes]="['notification']"></contact-info>
          </span>
          <span class="left10 medium" *ngIf="driverOtp">OTP: {{driverOtp}}</span>
        </div>
        <div class="left10">
          <button nz-button nzType="text" (click)="onBtnEditDriver()"
            class="btn-edit-driver">{{displayInfo.driver?.fullName ? 'Edit Driver': 'Assign Driver'}}</button>
        </div>
        <div style="margin-left: 10px;" class="driver-online-status clickable center-children "
          [ngClass]="{'online': isDriverOnline}" (click)="viewDriverAppStatus()">
          <i nz-icon nzType="mobile" nzTheme="outline" style="margin-right: 5px;"></i>
          {{isDriverOnline ? 'online' : 'offline'}}
        </div>
      </div>
    </div>
    <ng-container *ngIf="isShowSecondaryDriver">
      <div class="top10">
        <div class="label">Secondary Driver</div>
        <div class="flex">
          <div *ngIf="!displayInfo.secondaryDriver?.fullName">
            No Driver Assigned
          </div>
          <div *ngIf="displayInfo.secondaryDriver?.fullName">
            <a [routerLink]="[this.routeAdminDriverList, displayInfo.secondaryDriver?.id]"
              target="_blank"><span>{{displayInfo.secondaryDriver?.fullName}}</span></a>
          </div>
          <span class="left10 medium">
            <contact-info owner={{displayInfo.secondaryDriver?.phoneNumber}} type="phone" inline="true"
              [scopes]="['notification']"></contact-info>
          </span>
          <div class="left10">
            <a (click)="onBtnEditSecondaryDriver()" class="btn-edit-driver">{{displayInfo.secondaryDriver?.fullName ?
              'Edit Driver': 'Assign Driver'}}</a>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="flex">
      <div class="top10 group-tracking">
        <div class="label">Carrier Tracking URL</div>
        <div class="flex">
          <div *ngIf="!getCustomTrackingLink()" class="info">
            <button nz-button nzType="text" class="btn-edit-driver" (click)="onBtnAddCustomTracking()">
              <i nzTooltipTitle="Add your custom tracking link" nz-tooltip nz-icon nzType="plus-circle"
                nzTheme="outline"></i>
              Add new URL
            </button>
          </div>
          <div *ngIf="getCustomTrackingLink()" class="flex info">
            <a [href]="getCustomTrackingLink()" target="_blank" [nzTooltipTitle]="getCustomTrackingLink()" nz-tooltip>
              <i nz-icon nzType="link" nzTheme="outline"></i>
              Open Tracking URL
            </a>
            <div class="left20">
              <a (click)="onBtnAddCustomTracking()" nzTooltipTitle="Edit carrier tracking url"
                class="btn-edit-tracking">Edit</a>
            </div>
          </div>
        </div>
      </div>

      <div class="top10 group-trailer">
        <div class="label">Trailer Details</div>
        <div class="flex">
          <div *ngIf="!displayInfo.truckNumber" class="right40">Truck Number: N/A</div>
          <div *ngIf="displayInfo.truckNumber" class="right40">
            <span>Truck Number</span>
            <span class="medium left10 right20">{{displayInfo.truckNumber}}</span>
          </div>
          <div *ngIf="!displayInfo.trailerNumber">Trailer Number: N/A</div>
          <div *ngIf="displayInfo.trailerNumber">
            <span>Trailer Number</span>
            <span class="medium left10">{{displayInfo.trailerNumber}}</span>
          </div>
          <div class="left20">
            <button nz-button nzType="text" (click)="onBtnEditTrailerNumber()" class="btn-edit-driver">Edit</button>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isShowReferenceNumber()">
      <div class="flex">
        <div class="top10 group-trailer">
          <div class="label">Reference Number (Carrier)</div>
          <div class="flex">
            <div *ngIf="!displayInfo.referenceNumber">Reference Number: N/A</div>
            <div *ngIf="displayInfo.referenceNumber">
              <span>Reference Number</span>
              <span class="medium left10">{{displayInfo?.referenceNumber}}</span>
            </div>
            <div class="left20">
              <button nz-button nzType="text" (click)="onBtnEditReferenceNumber()" class="btn-edit-driver">Edit</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="separator h top15 bottom15"></div>
    <div>
      <div class="flex">
        <div class="driver-down-time-icon"><img src="/assets/img/dispatch-icons/hotel.svg" /></div>
        <div class="label left10" style="align-self: center;">Driver Down Time</div>
      </div>
      <div class="flex">
        <div *ngIf="!displayInfo.driverDownTime?.from">N/A</div>
        <div *ngIf="displayInfo.driverDownTime?.from && displayInfo.driverDownTime?.to" class="flex">
          <div>
            From <span class="medium left5">{{displayInfo.driverDownTime.from}}
              {{displayInfo.driverDownTime.timezoneShort}}</span>
          </div>
          <div style="margin-left: 40px;">
            To <span class="medium left5">{{displayInfo.driverDownTime.to}}
              {{displayInfo.driverDownTime.timezoneShort}}</span>
          </div>
        </div>
        <div class="left20">
          <button nz-button nzType="text" (click)="onBtnEditDriverDownTime()" class="btn-edit-driver">Edit</button>
        </div>
      </div>
    </div>
    <div class="separator h top15 bottom15"></div>
    <div display-carrier-cost [saveCarrierCost]="saveDataForCarrierCost" [saveCarrierCostSuccess]="saveCarrierCostSuccess"
      [note]="getNoteForUpdateCost()"
      [saveMarkAlreadyPaid]="saveDataMarkAlreadyPaid"
      [updateNoteCarrierPaid]="updateDataNoteCarrierPaid"
      [shouldDisableMarkAlreadyPaid]="!!displayInfo?.additionalCarrierCosts?.length"
      [getWarningContentServiceOptionsCost]="getWarningContentServiceOptionsCost"
      [confirmSyncServiceOptionsCostToOrder]="confirmSyncServiceOptionsCostToOrder"></div>
    <div class="separator h top15 bottom15"></div>
    <ng-container *ngIf="shouldCreateAdditionalCarrierCost && this.requirePermissions([this.PermissionCode.additional_carrier_cost.create])">
      <div>
        <button nz-button (click)="onBtnCreateAdditionalCost()">Create additional carrier cost</button>
      </div>
    </ng-container>
    <ng-container *ngIf="displayInfo?.additionalCarrierCosts?.length">
      <div *ngFor="let item of displayInfo.additionalCarrierCosts;let i = index">
        <div class="top10 bottom10 medium">Additional carrier cost {{ i + 1 }}</div>
        <div display-carrier-cost [jobId]="'no_job_additional_cost'" [carrierCost]="getAdditionalCost(item)"
          [saveCarrierCost]="saveAdditionalCarrierCost(item)"
          [saveMarkAlreadyPaid]="saveDataAdditionalCostMarkAlreadyPaid(item)"
          [updateNoteCarrierPaid]="updateDataAdditionalCostNoteCarrierPaid(item)"
          [shouldDisableMarkAlreadyPaid]="shouldDisableMarkAlreadyPaidAdditional(item)"
          [getWarningContentServiceOptionsCost]="getWarningContentServiceOptionsCost"
          [confirmSyncServiceOptionsCostToOrder]="confirmSyncServiceOptionsInAdditionnalCostToOrder(item)"></div>
        <div class="separator h top10 bottom10"></div>
      </div>
    </ng-container>
    <div carrier-cost-issue [jobId]="jobId" *ngIf="canAccessFinancialIssues" [saleRepEmails]="saleRepEmails"></div>
    <ng-container *ngIf="!isExternalRoute">
      <div class="separator h top15 bottom15"></div>
      <button *ngIf="!displayInfo.carrierBidId" (click)="onClickCreateCarrierBid()" nz-button
        class="btn-carrier-bid">Start Carrier Bid</button>
      <button *ngIf="displayInfo.carrierBidId" (click)="onClickViewCarrierBid()" nz-button class="btn-carrier-bid">Go to
        Carrier Bid</button>
    </ng-container>
  </div>
</div>