import { BaseComponent } from "@abstract/BaseComponent";
import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { DispatchService } from "../../dispatchService";
import RouteEntity from "../../entity/RouteEntity";
import { DialogService } from "@dialogs/dialog.service";
import { EmailBolComponent } from "./email-bol/email-bol.component";
import { EmailLoadTenderComponent } from "./email-load-tender/email-load-tender.component";
import { ModalHelper } from "@wearewarp/ng-antd";
import { DrawerService } from "@app/drawers/drawer.service";
import { UploadDocumentCustomer } from "./document-customer/upload-document";
import { DocumentCustomerComponent } from "./document-customer";
import { DateUtil } from "@services/date-utils";
import { DownloadingComponent } from "@app/admin/components/downloading/downloading.component";
import { WhenBy } from "@wearewarp/types/data-model";
import { EmailBolClientComponent } from "./email-bol-client/email-bol-client.component";
import { EmailThirdPartyBolComponent } from "./email-third-party-bol/email-third-party-bol.component";

@Component({
  selector: '[dispatch-documents]',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class DispatchDocuments extends BaseComponent {

  public jobId;
  public bolFileId;
  public bolInfo;
  public loadTenderFileId;
  public loadTenderInfo;
  public isLoading = true;
  public displayInfo: any = {};
  protected route: RouteEntity;
  public isAssignedCarrier: boolean = false;
  isVisibleDocumentCustomer = false;
  public clients;
  public shipments;
  public jobCode;

  @ViewChild('customerDocument') customerDocument: DocumentCustomerComponent;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private modalHelper: ModalHelper,
    protected dispatchService: DispatchService
  ) {
    super(activatedRoute)
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.dispatchService.routeData$.subscribe(() => {
        this.route = this.dispatchService.getRoute();
        this.resetData();
        this.isAssignedCarrier = this.route?.isAssignedCarrier();
        this.jobId = this.route?.getId();
        this.bolFileId = this.route?.getBolFileId();
        this.loadTenderFileId = this.route?.getAssignedCarrier()?.loadTenderFileId;
        this.clients = this.route?.getClients();
        this.shipments = this.route?.getShipments()?.map(item => item?.toJSON());
        this.jobCode = this.route.getCode();
        this.getData();
        this.autoRegenLoadtender();
      })
    )
    this.subscription.add(
      this.dispatchService.loading.subscribe(value => {
        this.isLoading = value;
      })
    )
  }

  getData() {
    let urls = [];
    if (this.bolFileId) urls.push(`${Const.APIV2(`jobs/${this.jobId}/document`)}?type=bol`);
    if (this.loadTenderFileId) urls.push(`${Const.APIV2(`jobs/${this.jobId}/document`)}?type=loadTender`);
    if (!urls.length) {
      this.loadTenderInfo = {};
      this.bolInfo = {};
      return
    };
    this.isLoading = true;
    this.api.concurrentGET(urls).subscribe(
      (resp) => {
        if (resp.length > 1) {
          this.bolInfo = resp[0].data;
          this.loadTenderInfo = resp[1].data;
        } else {
          if (this.bolFileId) {
            this.bolInfo = resp[0].data;
          } else if (this.loadTenderFileId) {
            this.loadTenderInfo = resp[0].data
          }
        }
        this.bolLastSent = this.extractLastSent(this.bolInfo);
        this.loadTenderLastSent = this.extractLastSent(this.loadTenderInfo);
        this.bolCreated = this.extractCreatedInfo(this.bolInfo?.insert);
        this.loadtenderCreated = this.extractCreatedInfo(this.loadTenderInfo?.insert);
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  bolCreated = '';
  loadtenderCreated = '';
  bolLastSent;
  loadTenderLastSent;

  private extractLastSent(data) {
    let lastSent = data?.lastSent;
    if (!lastSent) {
      return null;
    }
    return {
      time: DateUtil.dateToString(lastSent.insert.when, "MMM D, YYYY hh:mm:ss A"),
      sender: {
        email: lastSent.insert?.byUser?.email,
        name: this.getFullName(lastSent.insert?.byUser),
      },
      receiver: {
        email: lastSent.email
      }
    }
  }

  private extractCreatedInfo(insert: WhenBy): string {
    if (!insert?.when) return '';
    return DateUtil.format(insert.when, 'MMM D, YYYY hh:mm:ss A');
  }

  private resetData() {
    this.displayInfo = {};
    this.bolFileId = null;
    this.bolInfo = null;
    this.loadTenderFileId = null;
    this.loadTenderInfo = null;
    this.isAssignedCarrier = false;
  }

  autoRegenLoadtender() {
    if (this.route?.getAssignedCarrier()?.carrierId && !this.route?.getAssignedCarrier()?.loadTenderFileId) {
       this.api.POST(Const.APIURI_JOBS + `/${this.jobId}/load_tender`).subscribe((x) => {
        this.loadTenderFileId = x?.data.id;
        this.getData();
      })
    }
  }

  async onClick(action, event) {
    let forceUpdate = false;
    if (event.ctrlKey || event.metaKey) {
      forceUpdate = true;
    };

    switch (action) {
      case "email load tender":
        DialogService.openFormDialog1(EmailLoadTenderComponent, {
          nzComponentParams: {
            jobId: this.jobId,
            carrier: this.route?.getCarrier(),
            loadTenderFileId: this.loadTenderFileId,
            closeOnSuccess: true,
            updateSuccess: (resp) => {
            },
          },
          nzClassName: "email-load-tender-form",
        });
        break;
      case "email BOL":
        DialogService.openFormDialog1(EmailBolComponent, {
          nzComponentParams: {
            jobId: this.jobId,
            bolFileId: this.bolFileId,
            carrier: this.route?.getCarrier(),
            // clients: this.route?.getClients(),
            stops: this.route?.getStops(),
            closeOnSuccess: true,
            updateSuccess: (resp) => {
            },
          },
          nzClassName: "email-load-tender-form",
        });
        break;
      case "email BOL Client":
        DialogService.openFormDialog1(EmailBolClientComponent, {
          nzComponentParams: {
            jobId: this.jobId,
            clients: this.route?.getClients(),
            closeOnSuccess: true,
            updateSuccess: (resp) => {
            },
          },
          nzClassName: "email-load-tender-form",
        });
        break;
      case "email third party BOL":
        const {externalId} = this.shipments[0] || {}
        DialogService.openFormDialog1(EmailThirdPartyBolComponent, {
          nzComponentParams: {
            externalId,
            jobId: this.jobId,
            stops: this.route?.getStops(),
            closeOnSuccess: true,
            updateSuccess: (resp) => {
            },
          },
          nzClassName: "email-load-tender-form",
        });
        break;
      case "view BOL":
        if (this.bolInfo) {
          DialogService.preview([this.bolInfo], 0);
        } else {
          this.downloadBOL({ type: 'bol', forceUpdate })
        }
        break;
      case "download BOL":
        if (this.bolInfo) {
          this.downloadAttachedFileWithLoading({ ...this.bolInfo, type: 'bol', forceUpdate })
        } else {
          this.downloadBOL({ type: 'bol', forceUpdate })
        }
        break;
      case "re-generate BOL":
        if (this.bolInfo) {
          this.modalHelper.confirmYesNo(
            'Do you really want to re-generate BOL?',
            () => this.downloadAttachedFileWithLoading({ ...this.bolInfo, type: 'bol', forceUpdate: true })
          );
        } else {
          this.modalHelper.confirmYesNo(
            'Do you really want to re-generate BOL?',
            () => this.downloadBOL({ type: 'bol', forceUpdate: true })
          );
        }
        break;
      case "view Load Tender":
        if (this.loadTenderInfo) {
          DialogService.preview([this.loadTenderInfo], 0);
        }
        break;
      case "download Load Tender":
        this.downloadAttachedFileWithLoading({ ...this.loadTenderInfo, type: 'load_tender', forceUpdate })
        break;
      case "re-generate Load Tender":
        this.modalHelper.confirmYesNo(
          'Do you really want to re-generate Load Tender?',
          () => this.downloadAttachedFileWithLoading({ ...this.loadTenderInfo, type: 'load_tender', forceUpdate: true })
        );
        break;
      case "upload Document Customer":
        this.openDrawerUploadDocumentCustomer();
        break;
      case "note":
        break;
      default:
    }
  }

  loadingRef;
  private async downloadAttachedFileWithLoading(event) {
    if (!this.jobId) {
      return this.showWarning('', 'jobId is required.')
    }
    this.loadingRef = DialogService.openFormDialog1(DownloadingComponent, {});
    const eventName = event?.type;
    if (event.forceUpdate && event.type) {
      let url = `${Const.APIURI_JOBS}/${this.jobId}/${event.type}`;
      let response = await this.api.POST(url).toPromise().catch(e => {
        this.showErr(`Error when create File. ${e.message}`)
      });
      if (!response) return;
      let data = response.data;
      event = { ...event, ...data }
    };
    if (eventName == "bol") {
      event.name = `BOL_route_${this.jobCode}_${Date.now()}.pdf`;
    } else if (eventName == "bol-aci") {
      event.name = `ACI_BOL_route_${this.jobCode}_${Date.now()}.pdf`;
    } else if (eventName == "load_tender") {
      event.name = `LoadTender_${this.jobCode}_${Date.now()}.pdf`;
      this.loadTenderFileId = event.id;
      this.loadTenderInfo = event;
    }
    //define event để biết khi nào download xong.
    if (typeof event.isDownloading == "undefined") {
      const parent = this;
      Object.defineProperty(event, "isDownloading", {
        get() {
          return this._isDownloading;
        },

        set(value) {
          this._isDownloading = value;
          if (!value) parent.loadingRef?.close();
        },
      });
    }
    return this.downloadAttachedFile(event);
  }

  private async downloadBOL(event) {
    if (!this.jobId) {
      return this.showWarning('', 'jobId is required.')
    }
    if (event.forceUpdate && event.type) {
      return await this.downloadAttachedFileWithLoading(event);
    } else {
      this.loadingRef = DialogService.openFormDialog1(DownloadingComponent, {});
      let url = `${Const.APIURI_JOBS}/${this.jobId}/download-bol`;
      this.api.download(url).subscribe(
        (resp) => {
          let blob = new Blob([resp], { type: "application/pdf" });
          let url = URL.createObjectURL(blob);
          let fileName = `BOL_route_${this.jobCode}_${Date.now()}.pdf`;
          Utils.downloadFile(url, fileName);
          this.loadingRef.close();
        },
        (err) => {
          this.showErr(err);
          this.loadingRef.close();
        }
      );
    }
  }

  private openDrawerUploadDocumentCustomer() {
    if (!this.jobId) return;
    DrawerService.openFormDrawer1(UploadDocumentCustomer, {
      nzContentParams: {
        clients: this.clients,
        jobOrders: this.shipments,
        createSuccess: data => {
          this.customerDocument?.fetchData();
        },
        closeOnSuccess: true
      },
      nzWidth: 400,
      nzWrapClassName: 'wrap-drawer',
    });
  }

  get saksReportEnabled() {
    // TODO: move to config / metadata
    // Generate reports at backend
    return this.clients?.filter(it => it.id === '01H22NK3KK5KJHR8BX7AB9E4W1')?.length;
  }

  get ACIBOLEnabled() {
    // TODO: move to config / metadata
    return this.clients?.filter(it => it.id === '01JM1P5GNXJHQ72BED4PQB24ZB')?.length;
  }


  async downloadSaksManifest(event) {
    if (!this.jobId) {
      return this.showWarning('','jobId is required.')
    }
    let url = `${Const.APIV2('reports')}/saks-manifest`;
    this.route
    const name = this.route.getCode() + '_' + Date.now().toString();
    // TODO: move to config / metadata
    this.api.POST(url, {jobId: this.jobId, clientId: '01H22NK3KK5KJHR8BX7AB9E4W1'}, {responseType: 'arraybuffer'}).subscribe(
      (resp) => {
        let blob = new Blob([resp], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        let url = URL.createObjectURL(blob);
        let fileName = `${name}.xlsx`;
        Utils.downloadFile(url, fileName);
        this.loadingRef.close();
      },
      (err) => {
        this.showErr(err);
        this.loadingRef.close();
      }
    );
  }

  async downloadACIBOL(event) {
    if (!this.jobId) {
      return this.showWarning('','jobId is required.')
    }
    this.downloadAttachedFileWithLoading({type: 'bol-aci', forceUpdate: true})
  }

  get sourceThirdParty() {
    const {externalId} = this.shipments[0] || {}
    return externalId ? externalId.split('_')[0].toUpperCase() : null;
  }

}
