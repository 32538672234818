<div class="form-header no-border no-padding-bottom">
  <div class="form-title f18b">Time to start route before PU</div>
  <i class="ic-close clickable" (click)="onBtnCancel()" nz-icon nzType="close" nzTheme="outline"></i>
</div>

<form class="form-detail" [formGroup]="formInput" nz-form style="padding: 26px;">
  <div nz-row>
    <div nz-col [nzSpan]="24" class="flex">
      <div class="form-label-v2 single-line fix-height" style="width: 100px;">
        {{getLabel('hours')}}
        <span *ngIf="isRequired('hours') && getLabel('hours').length > 0" class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control>
          <input
            nz-input
            [formControlName]="'hours'"
            [placeholder]="getPlaceHolder('hours')"
            (input)="onInputChanged($event, 'hours')" 
            (keypress)="onInputKeyPress($event, 'hours')"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>

<div form-footer class="no-border"
  [canClickOK]="needUpdate" [canClickCancel]="true"
  nzIconOK="save" labelOK="Save" (onOK)="onBtnSave()"
  (onCancel)="onBtnCancel()"></div>
