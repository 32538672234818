<div class="revfenue-info">
    <div class="component-body">
        <nz-table #revenueTable [nzData]="data" nzSize="small" [nzShowPagination]="false" [nzLoading]="loading">
            <thead>
                <tr>
                    <th nzWidth="135px">Warp ID 
                        <copy-text class="left5" text="{{ getAllShipmentCodeAndWarpId() }}" tooltip="Copy all NEW & OLD WARPID" icon="copy"></copy-text>
                    </th>
                    <th nzWidth="120px">Customer</th>
                    <th nzWidth="120px">
                        S-Revenue
                        <i nz-icon nzType="info-circle" nzTheme="outline" 
                            nz-tooltip nzTooltipTitle="Shipment Revenue">
                        </i>
                    </th>
                    <th nzWidth="120px">Order Revenue</th>
                    <th nzWidth="150px">CSR</th>
                    <th *ngIf="requirePermissions([PermissionCode.payment.send_issue, PermissionCode.payment.view_issue])">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of revenueTable.data">
                    <td>
                        <a [routerLink]="[routeAdminShipmentList, data?.order?.id]" target="_blank">
                            <b>{{data?.order?.orderCode}}</b>
                            <br>
                            <ng-container *ngIf="hasShipmentCode(data)">
                                <span class="shipmentID">{{ data?.code }} <br/> ({{ showShipmentWarpId(data?.warpId) }})</span>
                            </ng-container>
                            <ng-container *ngIf="!hasShipmentCode(data)">
                                <span class="shipmentID">({{ showShipmentWarpId(data?.warpId) }})</span>
                            </ng-container>
                        </a>
                        <copy-text class="left5"  *ngIf="hasShipmentCode(data)" text="{{ getShipmentCode(data, true) }}" tooltip="Copy NEW WARPID" icon="copy"></copy-text>
                        <copy-text class="left5" text="{{ getShipmentCode(data) }}" tooltip="Copy OLD WARPID" icon="copy"></copy-text>
                    </td>
                    <td nzAlign="right">{{data.client}}</td>
                    <td nzAlign="right">{{data.customerRate}}</td>
                    <td nzAlign="right">{{data.order?.totalShipmentsCost}}</td>
                    <td>{{data.clientSalesRep}}</td>
                    <td *ngIf="requirePermissions([PermissionCode.payment.send_issue, PermissionCode.payment.view_issue])">
                        <div class="actions">
                            <div class="btn-issue" *ngIf="requirePermissions([PermissionCode.payment.send_issue])" (click)="onOpenSendMail(data)">
                                <span nz-icon nzType="mail" nzTheme="outline"></span>&nbsp;Email to Sales
                            </div>
                            <div class="btn-issue" *ngIf="requirePermissions([PermissionCode.payment.view_issue])" (click)="onOpenIssue(data.order)">
                                <span nz-icon nzType="issues-close" nzTheme="outline"></span>&nbsp;Order Issue
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </nz-table>

        <div *ngIf="canDownloadInvoice" class="top20 bottom20">
            <button nz-button (click)="onDownloadInvoice()">Download all order invoices</button>
        </div>

    </div>
</div>