<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

<div *ngFor="let customer of listData ; let i = index" class="customer-item" style="padding: 4px 0;">
  <div class="flex">
    <a [routerLink]="[this.routeAdminClientList, customer.id]" target="_blank" class="customer-name">{{ customer.name }}:</a>
    <div class="flex1"></div>
    <i nz-icon nzType="form" nzTheme="outline" class="clickable" nz-tooltip nzTooltipTitle="Customer notes" (click)="showCustomerNotes(customer)"></i>
  </div>
  <div class="flex">
    <div>
      <div *ngFor="let item of customer.contactInfos" class="contact-item">
        <div class="label" >{{item.label}}:</div>
        <div class="value">{{ item.value }}</div>
      </div>
      <div class="contact-item">
        <div class="label" >Route Tracking Link:</div>
        <span class="value">
          <a [href]="customer.trackingUrl">Open</a>
          <i class="left10"
            nz-icon nzType="copy" nzTheme="outline" 
            nz-tooltip nzTooltipTitle="Copy" (click)="copyTrackingLink(customer)">
          </i>
        </span>
      </div>
    </div>
    <div class="flex1"></div>
    <div class="customer-notes" [ngClass]="{'show': customer.showNote}">
      <div dispatch-customer-note
        [customerId]="customer.id"
        [data]="customer.note"
        (fetchDataDone)="onCustomerNotesFetchingDone(customer, $event)">
      </div>
    </div>
  </div>
</div>
