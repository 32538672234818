import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { AssignedCarrier, Driver, Job, WhenBy, WorkTask, WorkTaskHistory } from "@wearewarp/types/data-model";
import { DispatchService } from "../../dispatchService";
import { DialogService } from "@dialogs/dialog.service";
import { ResendConfirmBookingDialog } from "./resend-confirm-booking-dialog";
import { Utils } from "@services/utils";
import { DateUtil } from "@services/date-utils";
import { RequestCreateWorkTaskHistory } from "./interface";

type ByCollectionEnum = 'users' | 'drivers' | 'clients' | 'carriers';
interface IByData {
  collection: ByCollectionEnum,
  id?: string,
  warpId?: number,
}

@Component({
  selector: "[dispatch-work-task]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class DispatchWorkTaskInfo extends BaseComponent {
  
  constructor(
    protected dispatchService: DispatchService
  ) {
    super()
  }

  isLoading: boolean = false;
  jobId: string;
  routeData: Job;
  assignedCarrier: AssignedCarrier;
  tasks: (WorkTask & {logHistory?: WorkTaskHistory[] })[] = [];
  logSendConfirmBookingEmail = [];
  automationTrackingUrl: string;
  displayInfo: any = {};
  isAllItemsConfirmed: boolean = false;
  emailWonBidSentBy: any;
  driverContent: { text: string, status: string } = { text: '', status: '' };

  ngOnInit() {
    super.ngOnInit();
    this.subscription.add(
      this.dispatchService.routeData$.subscribe(() => {
        const route = this.dispatchService.getRoute();
        this.routeData = route?.getData();
        this.jobId = route?.getId();
        this.assignedCarrier = route?.getData().assignedCarrier;
        this.automationTrackingUrl = (<any>this.routeData)?.automationTrackingUrl;
        this.emailWonBidSentBy = this.routeData?.assignedCarrier?.sentEmailWonBidBy;
        this.fetchRouteWorkTasks();
        this.fetchDrivers();
      })
    )
    this.fetchRouteWorkTasks();
    this.fetchDrivers();
  }

  get isHasTask() {
    return this.tasks.length > 0;
  }

  get isAssignedCarrier() {
    return !!this.assignedCarrier?.carrierId;
  }
  
  isTaskConfirmBooking(task) {
    return task.type === 'routeConfirmation';
  }

  fetchRouteWorkTasks() {
    if (!this.jobId) return;
    this.isLoading = true;
    const url = `${Const.API_SERVICE_WORK_QUEUE}/w/d/tasks/job/${this.jobId}`;
    this.api.GET(url).subscribe(
      (resp) => {
        this.isLoading = false;
        this.tasks = resp.data?.list_data || [];
        this.buildData();
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
      }
    )
  }

  drivers: Driver[] = [];
  fetchDrivers() {
    if (!this.assignedCarrier?.carrierId) return;
    this.isLoading = true;
    const url = `${Const.APIURI_DRIVERS}?status=1&limit=-1&carrierId=${this.assignedCarrier?.carrierId}`;
    this.api.GET(url).subscribe(
      (resp) => {
        this.isLoading = false;
        this.drivers = resp.data?.list_data || [];
        this.getDriverContent();
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
      }
    )
  }

  buildData() {
    let allHistories = this.tasks.map(task => task.logHistory).flat();
    this.logSendConfirmBookingEmail = allHistories.filter(h => h.action?.includes('sendConfirmBookingEmail'));
    this.displayInfo = {
      itemsConfirmed: this.getDisplayItemsConfirmed()
    }
  }

  getDisplayItemsConfirmed() {
    if (!this.isAssignedCarrier) return 'Need carrier';
    let cnt = 0;
    let requireCarrierAcceptLoadtender = this.routeData?.settings?.isRequireCarrierAcceptLoadTender
    let numNeedConfirm = requireCarrierAcceptLoadtender ? 3 : 2;
    if (this.assignedCarrier?.loadTenderStatus === 'accepted' && requireCarrierAcceptLoadtender) cnt++;
    if (this.assignedCarrier?.isReady) cnt++;
    if (this.assignedCarrier?.confirmPickupTime) cnt++;
    if (cnt === numNeedConfirm) {
      this.isAllItemsConfirmed = true;
    }
    return `${cnt}/${numNeedConfirm} confirmed`;
  }

  get confirmItemStatus() {
    if (!this.isAssignedCarrier) return 'need-carrier';
    if (this.isAllItemsConfirmed) return 'all-confirmed';
    return '';
  }

  onBtnOpenSendEmailDialog(task) {
    const route = this.dispatchService.getRoute();
    const stops = route?.getStops();
    const pickCity = stops?.[0]?.getAddress()?.city;
    const dropCity = stops?.[stops.length - 1]?.getAddress()?.city;
    if (this.isTaskConfirmBooking(task)) {
      let carrierContact: any = this.routeData?.carrier?.basicInfo?.owner || this.routeData?.carrier?.basicInfo?.contacts?.[0];
      let contactName = carrierContact?.firstName || carrierContact?.fullName;
      let emailContact = carrierContact?.email;
      let emailSubject = `[Action required] ${this.routeData?.code} Booking Confirmation - ${pickCity} - ${dropCity}`;
      let confirmLink = this.automationTrackingUrl;
      DialogService.openDialog(ResendConfirmBookingDialog, {
        nzComponentParams: {
          jobId: this.jobId,
          contactName,
          emailContact,
          emailSubject,
          confirmLink,
          onSuccess: () => {
            this.resendConfirmBookingEmailSuccess(task);
          }
        },
        nzTitle: 'Resend Booking Confirmation email',
        nzClosable: true,
        nzWidth: '510px'
      })
    }
  }

  resendConfirmBookingEmailSuccess(task) {
    const workTaskId = task.id;
    const params: RequestCreateWorkTaskHistory = {
      taskId: workTaskId,
      action: 'sendConfirmBookingEmail',
      object: { entity: 'jobs', entityId: this.jobId },
      source: { entity: 'warp-web' },
      evidences: { jobId: this.jobId, status: this.routeData?.status }
    };
    let url = `${Const.API_SERVICE_WORK_QUEUE}/history`;
    this.isLoading = true;
    this.api.POST(url, params).subscribe(
      (resp) => {
        this.isLoading = false;
        this.fetchRouteWorkTasks();
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
      }
    )
  }

  async onBtnOpenChecklistDialog(task) {
    let requireCarrierAcceptLoadtender = this.routeData?.settings?.isRequireCarrierAcceptLoadTender;
    const isConfirmedLoadtender = this.assignedCarrier?.loadTenderStatus === 'accepted' ? true : false;
    const isConfirmedReady = this.assignedCarrier?.isReady ? true : false;

    let acceptedLoadTenderBy: any = this.routeData?.assignedCarrier?.acceptedLoadTenderBy || null;
    if (acceptedLoadTenderBy) {
      const result = await this.getWhenByData(acceptedLoadTenderBy);
      acceptedLoadTenderBy = { ...acceptedLoadTenderBy, ...result };
    }
    let confirmedReadyBy: any = this.routeData?.assignedCarrier?.confirmedReadyBy || null;
    if (confirmedReadyBy) {
      const result = await this.getWhenByData(confirmedReadyBy);
      confirmedReadyBy = { ...confirmedReadyBy, ...result };
    }

    let confirmedPickupTimeBy: any = this.routeData?.assignedCarrier?.confirmPickupTime || null;
    if (confirmedPickupTimeBy) {
      const result = await this.getWhenByData(confirmedPickupTimeBy);
      confirmedPickupTimeBy = { ...confirmedPickupTimeBy, ...result };
    }
    
    let textAcceptedLoadTenderBy = isConfirmedLoadtender ? `- by ${ acceptedLoadTenderBy?.basicInfo?.name || acceptedLoadTenderBy?.name || this.getFullName(acceptedLoadTenderBy)} at ${this.$formatDate(acceptedLoadTenderBy?.when) } (EST)` : '';
    let textConfirmedReadyBy = isConfirmedReady ? `- by ${ confirmedReadyBy?.basicInfo?.name || confirmedReadyBy?.name || this.getFullName(confirmedReadyBy)} at ${this.$formatDate(confirmedReadyBy?.when) } (EST)` : '';
    let textConfirmedPickupTimeBy = confirmedPickupTimeBy ? `- by ${ confirmedPickupTimeBy?.basicInfo?.name || confirmedPickupTimeBy?.name || this.getFullName(confirmedPickupTimeBy)} at ${this.$formatDate(confirmedPickupTimeBy?.when) } (EST)` : '';

    let content = '<ul>';
    if (requireCarrierAcceptLoadtender) {
      content = content + `<li>Carrier ${!isConfirmedLoadtender ? 'hasn\'t ' : ''}accepted Load Tender ${textAcceptedLoadTenderBy}</li>`;
    }
    content = content + `<li>Carrier ${!isConfirmedReady ? 'hasn\'t':''} confirmed Equipment ${textConfirmedReadyBy}</li>`;

    content = content + `<li>Carrier ${!confirmedPickupTimeBy ? 'hasn\'t':''} confirmed Pickup Time ${textConfirmedPickupTimeBy}</li>`;
    content = content + '</ul>';
    this.confirm(content, () => {}, 'Confirm Booking status')
  }

  private async getWhenByData(params: WhenBy) {
    if (params.byCarrierId) {
      let byData: IByData = { collection: 'carriers' };
      if (Utils.isNumber(params.byCarrierId)) byData.warpId = params.byCarrierId;
      if (params.byId) byData.id = params.byId;
      const res = await this.getWhenbyDataFromAPI(byData);
      return res;
    }
    if (params.byDriverId) {
      let byData: IByData = { collection: 'drivers' };
      if (Utils.isNumber(params.byDriverId)) byData.warpId = params.byDriverId;
      if (params.byId) byData.id = params.byId;
      const res = await this.getWhenbyDataFromAPI(byData);
      return res;
    }
    if (params.by) {
      let byData: IByData = { collection: 'users' };
      if (Utils.isNumber(params.by)) byData.warpId = params.by;
      if (params.byId) byData.id = params.byId;
      const res = await this.getWhenbyDataFromAPI(byData);
      return res;
    }
  }

  async getWhenbyDataFromAPI(params: IByData) {
    let url = `${Const.APIURI_USERS}/get-when-by-info`;
    const response = await this.api.POST(url, params).toPromise();
    return response?.data;
  }

  $formatDate = (date: Date) => {
    return DateUtil.displayLocalTime(date, { format: 'MM/DD/YYYY hh:mm A', timezone: 'America/New_York' });
  }

  async getDriverContent() {
    if(!this.routeData?.assignedDriver?.driverId) {
      this.driverContent = {
        text: 'No driver assigned',
        status: 'pending'
      };
      return;
    }

    const hasOneDriver = this.drivers.length === 1 ? true : false;
    if(hasOneDriver) {
      this.driverContent = { text: 'Driver is auto assigned', status: 'pending' };
      return;
    }

    let assignedDriverBy: any = this.routeData?.assignedDriver?.update || null;
    if (assignedDriverBy) {
      const result = await this.getWhenByData(assignedDriverBy);
      assignedDriverBy = { ...assignedDriverBy, ...result };
    }

    if(!assignedDriverBy) {
      this.driverContent = { text: 'Driver is assigned but not yet confirmed ready', status: 'pending' };
      return;
    }

    const message = `Driver is assigned by ${ assignedDriverBy?.basicInfo?.name || assignedDriverBy?.name || this.getFullName(assignedDriverBy)} at ${this.$formatDate(assignedDriverBy?.when) } (EST)`
    this.driverContent = {
      text: message,
      status: 'confirmed'
    };
  }
}