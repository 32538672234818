import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { environment } from "@env/environment";

@Component({
  selector: "app-email-third-party-bol",
  templateUrl: "./email-third-party-bol.component.html",
  styleUrls: ["./email-third-party-bol.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EmailThirdPartyBolComponent extends BaseFormDialog1 implements OnInit {
  protected formGroupDeclaration: FormGroupDeclaration = {
    remark: { label: "Remark" },
    sendToPickupStop: { label: "Pickup Stop" },
  };
  constructor() {
    super();
  }

  @Input() jobId;
  @Input() stops = [];
  @Input() externalId;
  public isError = false;
  public isLoading = false;
  public confirmData: any = {};
  public inConfirm: boolean = false;
  public indeterminate = false;
  public bolLink: string = null;

  allContacts = new Map();
  sendContacts = new Set();
  ngOnInit(): void {
    this.getData();
    this.loadExernalInfo();
  }

  loadExernalInfo() {
    const url = environment.backendUrl + `/v2/pricing/quote/booked/${this.externalId}`
    this.isLoading = true;
    this.api.GET(url).subscribe(
      (res) => {
        this.setInfo(res);
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
      }
    )
  }

  setInfo(data) {
    const source = this.externalId ? this.externalId.split('_')[0].toUpperCase() : null;
    switch (source) {
      case "WWEX":
        this.setWWEXInfo(data);
        break;
      case "PRIORITY1":
        this.setP1Info(data);
        break;
      default:
        break;
    }
  }

  setWWEXInfo(data) {
    const { shipmentOrderResponse } = data || {}
    const documentList: any[] = [];
    for (let item of shipmentOrderResponse?.order?.orderedItemList ?? []) {
      for (let doc of item.documentList ?? []) {
        documentList.push(doc)
      }
    }
    let doc = documentList.filter(it => it.docType == "BILL_OF_LADING")[0]
    if (!doc){
      this.isLoading = false;
      return;
    }
    if (doc.url) {
      this.bolLink = doc.url;
      this.isLoading = false;
      return;
    }
    const url = environment.backendUrl + `/v2/pricing/quote/booked/WWEX_${data.id}/documents/${doc.s3fileName}`
    this.isLoading = true;
    this.api.GET(url).subscribe(
      (res) => {
        this.bolLink = res[0].url;
        this.isLoading = false;
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
      }
    )
  }

  setP1Info(data) {
    const { capacityProviderBolUrl } = data?.dispatchShipment || {};
    this.bolLink = capacityProviderBolUrl;
    this.isLoading = false;
  }

  hasSendTo(type) {
    return this.getItemValue(type);
  }

  get canClickOK() {
    let data: any = this.getFormData_JSON(true);
    // nếu select stop nhưng không chọn task
    if (data.sendToPickupStop && this.sendContacts.size === 0) return false;
    //nếu không chọn cả carrier & customer
    if (!data.sendToPickupStop) return false;
    // nếu không có BOL link
    if (!this.bolLink) return false;
    return true;
  }

  getData() {
    this.createFormInput();

    this.stops.forEach((stop) => {
      if (stop.getType() !== Const.TaskType.PICKUP) return;
      const tasks = stop.getTasks();
      tasks.forEach((task) => {
        const email = task.getPrimaryContact()?.email;
        const name = this.getFullName(task.getPrimaryContact());
        if (email && !this.allContacts.has(email)) {
          this.allContacts.set(email, { name: name, email: email, type: "Warehouse" });
        }
      });
    });
  }

  onEditEmail(formControlName) {
    //khi click btn edit trên form (cạnh input) thì enable để user có thể edit input.
    if (this.getItemByKey(formControlName).enabled)
      this.getItemByKey(formControlName).disable();
    else this.getItemByKey(formControlName).enable();
  }

  onBtnSave() {
    let json: any = this.getFormData_JSON(true);
    //ở lần ấn đầu, hiển thị form confirm thay vì submit luôn.
    if (!this.inConfirm) {
      this.confirmData = json;
      this.inConfirm = true;
      return;
    }

    //lần ấn tiếp theo (confirm) thì call API
    let contacts = [];

    if (json.sendToPickupStop && this.sendContacts.size > 0) {
      const _sendContacts = Array.from(this.sendContacts.values()).map(it => this.allContacts.get(it));
      contacts = [...contacts, ..._sendContacts];
    }

    const data = {
      contacts: contacts,
      remark: json.remark,
      jobId: this.jobId,
      bolLink: this.bolLink,
      source: this.externalId ? this.externalId.split('_')[0] : null
    };

    this.startProgress();
    this.isLoading = true;
    this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/send-third-party-bol`, data).subscribe(
      (resp) => {
        this.stopProgress();
        this.isLoading = false;
        this.closeDialog();
        this.showSuccess("BOL email has been sent successfully.");
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.isLoading = false;
        this.closeDialog();
      }
    );
  }
  onBtnCancel() {
    //back to form
    if (this.inConfirm) {
      this.inConfirm = false;
      return;
    }
    //close dialog
    this.closeDialog();
  }

  onCheckPUStop(checked) {
    this.indeterminate = false;
    if (checked) {
      this.sendContacts = new Set(this.allContacts.keys());
    }
  }

  onSelectStop(event, value) {
    console.log("onSelectStop", event, value);
    if (!event) return this.sendContacts.delete(value);
    this.sendContacts.add(value);
  }
}
