import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { DateUtil } from '@services/date-utils';
import { Log } from '@services/log';
import { MasterData } from '@services/master.data';
import { startOfDay, endOfDay } from 'date-fns'
import { Utils } from '@services/utils';
import { FilterInfo } from '@wearewarp/types/data-model';
import dayjs from 'dayjs';
@Component({
  selector: 'filter-modal',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class FilterModal extends BaseFormDialog1 {
  onSave: (data, cb?) => any;
  @Input() filterInfo: FilterInfo;

  dateRangePreset: number;
  pickLocationData = {};
  dropLocationData = {};
  isClearingEvent = false;
  _vehicleTypes: any[] = [];
  walmartOptions = [
    { key: "thirdPartyClientId", value: "0", label: "Walmart" },
    { key: "thirdPartyClientId", value: "2", label: "Sam's Club" },
    { key: "thirdPartyClientId", value: "1408", label: "West Marine" },
    { key: "thirdPartyClientId", value: "1033", label: "Sherwin Williams" },
    { key: "thirdPartyClientId", value: "1020", label: "The Home Depot" },
    { key: "thirdPartyClientId", value: "1511", label: "At Home" },
    { key: "thirdPartyClientId", value: "1476", label: "Sur La Table" },
    { key: "thirdPartyClientId", value: "1549", label: "Urban Stems" },
    { key: "thirdPartyClientId", value: "1455", label: "PPG" },
    { key: "thirdPartyClientId", value: "1491", label: "West Marine-OR" },
  ]

  constructor() {
    super();
   }
   protected formGroupDeclaration: FormGroupDeclaration = {
      name: { label: "Filter Name", placeHolder: "Enter filter name" },
      status: { label: "Status", placeHolder: "Select", notAcceptEmpty: true },
      clientId: { label: 'Customer', notAcceptEmpty: true, placeHolder: 'Select' },
      thirdPartyClientId: { label: 'Third Party Customer', notAcceptEmpty: true, placeHolder: 'Select' },
      shipmentType: { label: 'Shipment Type', placeHolder: 'Select', notAcceptEmpty: true},
      carrierId: { label: 'Carrier', notAcceptEmpty: true, placeHolder: 'Select' },
      driverId: { label: '', notAcceptEmpty: true },
      needReasonCode: { label: '', type: 'boolean', notAcceptEmpty: true},
      delayOTP: { label: '', type: 'boolean', notAcceptEmpty: true},
      delayOTD: { label: '', type: 'boolean', notAcceptEmpty: true},
      pickupState: { label: 'Pickup State', placeHolder: 'Select Pick', notAcceptEmpty: true },
      dropoffState: { label: 'Delivery State', placeHolder: 'Select Drop', notAcceptEmpty: true },
      pickupWarehouseId: {label: 'Pickup Warehouse', placeHolder: 'Select', notAcceptEmpty: true},
      dropoffWarehouseId: {label: 'Dropoff Warehouse', placeHolder: 'Select', notAcceptEmpty: true},
      pickupZipcode: {label: 'From Zipcode', placeHolder: 'From Zipcode', notAcceptEmpty: true},
      dropoffZipcode: {label: 'To Zipcode', placeHolder: 'To Zipcode', notAcceptEmpty: true},
      // equipment: { label: 'Equipment', placeHolder: 'Select', notAcceptEmpty: true },
      vehicleTypes: { label: 'Equipment', type: 'array', placeHolder: 'Select', initialValue: [] },
      fromDate: { label: 'From date', type: 'date', notAcceptEmpty: true, getValue: DateUtil.getDateFrom },
      toDate: { label: 'To date', type: 'date', notAcceptEmpty: true, getValue: DateUtil.getDateTo },
      fromTimezone: { label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
      toTimezone: { label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
      picId: { label: 'Dispatcher', placeHolder: 'Select', notAcceptEmpty: true },
      carrierSaleRepId: { label: 'Carrier Sales Rep', placeHolder: 'Select', notAcceptEmpty: true },
      clientSaleRepId: { label: 'Client Seller', placeHolder: 'Select', notAcceptEmpty: true },
      clientServiceUserId: { label: 'Client Account Owner', placeHolder: 'Select', notAcceptEmpty: true },
      clientServiceRepUserId: { label: 'Client Service Rep', placeHolder: 'Select', notAcceptEmpty: true },
      archivedTrackingTask: { label: 'Task queue', type: 'boolean', placeHolder: 'Select', notAcceptEmpty: true},
      clientFilterType: { label: 'Client Filter Type', placeHolder: 'Select', notAcceptEmpty: true, initialValue: 'include' },
      vehicleTypesFilterType: { label: 'Vehicle Type Filter Type', placeHolder: 'Select', notAcceptEmpty: true, initialValue: 'include' },
      shipmentTypeFilterType: { label: 'Shipment Type Filter Type', placeHolder: 'Select', notAcceptEmpty: true, initialValue: 'include' },
      carrierFilterType: { label: 'Carrier Filter Type', placeHolder: 'Select', notAcceptEmpty: true, initialValue: 'include' },
   }
   get formInputKeys(): Array<string> {
    return this._formInputKeys;
  }
  @Input() dataForm: any;
  @Input() urlMyLoad: any;
  @Input() page: any;
  @Input() search: any;
  @Input() lastUpdated: any;
  @Input() lastUpdatedTime: any;
  @Input() requestId: any;
  public listTimezones = DateUtil.listUsTimezones;

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchListCarriers();
    this.fetchListDispatchers();
    this.fetchListCarrierSales();
    this.fetchListClientSales();
    if (this.dataForm?.carrierId && Utils.isString(this.dataForm?.carrierId)) {
      this.shouldSelectMultipleCarrier = false;
    }
    if (this.filterInfo?.id) {
      this.dataForm.name = this.filterInfo.data.name;
    }
    if (this.dataForm?.vehicleTypes) {
      this._vehicleTypes = this.dataForm.vehicleTypes;
    }
    if (this.dataForm?.fromDate && this.dataForm.fromTimezone) {
      let timezone = DateUtil.mapTimezoneUS(this.dataForm.fromTimezone);
      const date = DateUtil.convertLocalTime2(this.dataForm.fromDate, timezone).toISOString();
      this.dataForm.fromDate = new Date(date);
    }
    if (this.dataForm?.toDate && this.dataForm.toTimezone) {
      let timezone = DateUtil.mapTimezoneUS(this.dataForm.toTimezone);
      const date = DateUtil.convertLocalTime2(this.dataForm.toDate, timezone).toISOString();
      this.dataForm.toDate = date;
    }
    this.pickLocationData = {
      state: this.dataForm?.pickupState,
      zipcode: this.dataForm?.pickupZipcode,
      warehouseId: this.dataForm?.pickupWarehouseId
    }
    this.dropLocationData = {
      state: this.dataForm?.dropoffState,
      zipcode: this.dataForm?.dropoffZipcode,
      warehouseId: this.dataForm?.dropoffWarehouseId
    }
    this.setFormValues(this.dataForm);
  }

  public listCarriers = [];
  public listCarrierSales = [];
  public listClientSales = [];
  public listDispatchers = [];
  public isFetchingCarrier = false;
  public isFetchingCarrierSaleRep = false;
  listDispatcherForDropdown: any[] = [];
  dispatchersHaveJobs = [];
  public countriesStates = MasterData.getCountriesStates_forSelectGroup();
  public listShipmentTypes = Const.ShipmentTypesArray;
  public showDispatcher = false;
  public shouldSelectMultipleCarrier = true;    // dynamic switch mode for nz-select
  private fetchListCarriers() {
    let url = `${Const.APIURI_CARRIERS}/list/all_for_filter`;
    this.isFetchingCarrier = true;
    this.api.GET(url).subscribe(
      (resp) => {
        Log.d("fetchListData done ", resp);
        this.listCarriers = resp.data.list_data;
        this.isFetchingCarrier = false;
      },
      (err) => {
        this.showErr(err);
        this.isFetchingCarrier = false;
      }
    );
  }

  public isFetchingClientSaleRep = false;
  private fetchListClientSales(silent: boolean = false) {
    this.isFetchingClientSaleRep = true;
    this.api.getListClientSalesRepUsersFilter().subscribe(
      resp => {
        this.listClientSales = resp?.data?.list_data ?? [];
        this.isFetchingClientSaleRep = false;
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingClientSaleRep = false;
      }
    );
  }
  private fetchListCarrierSales(silent: boolean = false) {
    this.isFetchingCarrierSaleRep = true;
    this.api.getListAdminUsers().subscribe(
      resp => {
        this.listCarrierSales = resp?.data?.list_data ?? [];
        this.isFetchingCarrierSaleRep = false;
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingCarrierSaleRep = false;
      }
    );
  }
  public getCarrierMC(carrier): string {
    return carrier?.basicInfo?.mc ?? "";
  }

  public getCarrierDOT(carrier): string {
    return carrier?.basicInfo?.dot ?? "";
  }
  getTimezoneShort() {
    const localTimeZone = DateUtil.getLocalTimeZone();
    const localTimeZoneShort = DateUtil.timezoneStandardToUsShort(localTimeZone);
    return localTimeZoneShort || '';
  }
  public startOfDay(result: Date): Date {
    return result ? startOfDay(result) : null;
  }
  public endOfDay(result: Date): Date {
    return result ? endOfDay(result) : null;
  }
  public getCarrierNameWithMCandDOT(carrier): string {
    if (carrier?.basicInfo?.mc && carrier?.basicInfo?.dot)
      return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc}/DOT:${carrier?.basicInfo?.dot})`;
    if (carrier?.basicInfo?.mc) return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc})`;
    if (carrier?.basicInfo?.dot) return `${carrier?.basicInfo?.name} (DOT:${carrier?.basicInfo?.dot})`;
    return carrier?.basicInfo?.name ?? "";
  }
  public getListDispatcherForDropdown() {
    let list = [...this.listDispatchers];
    return list.map(dispatcher => {
      const item = this.dispatchersHaveJobs.find(i => i._id == dispatcher.id);
      dispatcher.jobCount = item?.jobCount || 0;
      return dispatcher;
    }).filter(dispatcher => dispatcher.jobCount);
  }
  public dispatcherName(item) {
    let name: string = this.getFullName(item);
    if (item.jobCount) {
      name = `${name} (${item.jobCount})`;
    }
    return name;
  }

  getApiListDataForFilter(key: string): string|undefined {
    switch (key) {
      case 'clientId': return `${Const.APIURI_CLIENTS_FOR_FILTER}?includeSubAccount=true`;
      default: return;
    }
  }

  private buildQuery(): any {
    let q = { page: this.page || 1, loaded: Date.now() };
    if (this.search) {
      q["search"] = this.search;
    }
    let condition: any = this.getFormData_JSON(true);
    if (condition.status && !Utils.isArrayNotEmpty(condition.status)) {
      delete condition.status;
    }
    if (this.needPODChecked) {
      condition.isNeedPOD = true;
    }
    if (this.isWalmartScheduledLessThanDuration) {
      condition.isWalmartScheduledLessThanDuration = true;
    }
    if (this.includeCrossdock) {
      condition.includeCrossdock = true;
    }
    if (condition.sort) {
      q["sort"] = condition.sort;
      delete condition.sort;
    }
    const f = JSON.stringify(condition);
    if (f.length > 2) {
      q["filter"] = f;
    }
    q["tab"] = Utils.parseQueryStringFromUrl(this.router.url)?.tab || Const.DispatchTab.all_load;
    return q;
  }

  public getListDispatcherHaveJobAfterFilter() {
    this.listDispatcherForDropdown = this.listDispatcherForDropdown.map((dispatcher) => {
      dispatcher.jobCount = 0;
      return dispatcher;
    });
    const query = this.buildQuery();
    this.api.POST(`${Const.APIURI_JOBS}/count_job_for_dispatcher_list`, query).subscribe(
      resp => {
        this.dispatchersHaveJobs = resp.data.list_data;
        setTimeout(() => {
          this.listDispatcherForDropdown = this.getListDispatcherForDropdown();
        }, 100);
      }, err => {
        Log.e(err);
      }
    );
  }
  public isFetchingDispatcher = false;
  private fetchListDispatchers(silent: boolean = false) {
    this.isFetchingDispatcher = true;
    this.api.getListDispatcherUsersFilter().subscribe(
      resp => {
        this.listDispatchers = resp?.data?.list_data ?? [];
        this.isFetchingDispatcher = false;
        this.getListDispatcherHaveJobAfterFilter();
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          this.showErr(err);
        }
        this.isFetchingDispatcher = false;
      }
    );
  }

  onChange(event, key) {
    if (key == 'vehicleTypes') {
      this.setItemValue(key, event || []);
      return;
    }
    this.fetchListDispatchers();
  }

  onChangeCarrier(value, key){
    if(value != 'no') {
      this.needCarrierChecked = false;
    }
    if(value == 'no') {
      this.needCarrierChecked = true;
      this.needPODChecked = false;
    }
    let lastValue = Utils.isArray(value) ? value[value.length - 1] : value;
    if (this.shouldSelectMultipleCarrier) {
      if ((lastValue === 'no' || lastValue === 'yes')) {
        this.shouldSelectMultipleCarrier = false;
        setTimeout(() => this.setItemValue('carrierId', lastValue), 5);
      }
    } else {
      if (lastValue !== 'no' && lastValue !== 'yes') {
        this.shouldSelectMultipleCarrier = true;
        setTimeout(() => this.setItemValue('carrierId', [lastValue]), 5);
      }
    }
  }

  onChangeDispatcher(value, key){
    if(value != 'no') {
      this.needDispatcherChecked = false;
    }
    if(value == 'no') {
      this.needDispatcherChecked = true;
      this.needPODChecked = false;
    }
  }

  // "Need Dispatcher" option is a shortcut of combining 2 conditions below
  // Status: Created
  // Dispatcher: has not assigned yet
  public needDispatcherChecked = false;
  onCheckboxNeedDispatcher(event) {
    this.needPODChecked = false;
    if(event == true) {
      this.setItemValue('picId', 'no')
    } else {
      this.setItemValue('picId', null)
    }
  }

  // "Need Carrier" option is a shortcut of combining 2 conditions below
  // Status: Created
  // Carrier: has not assigned yet
  public needCarrierChecked = false;
  onCheckboxNeedCarrier(event) {
    this.needPODChecked = false;
    if(event == true) {
      this.shouldSelectMultipleCarrier = false;
      setTimeout(() => this.setItemValue('carrierId', 'no'), 5);
    } else {
      this.setItemValue('carrierId', null)
    }
  }

  public needPODChecked = false;
  onCheckboxNeedPOD(event) {
    this.needDispatcherChecked = false;
    this.needCarrierChecked = false;
    this.needDriverChecked = false;
    if(event == true) {
      if(this.getFormItemValue('picId') == "no") {
        this.setItemValue('picId', null)
      }
      if(this.getFormItemValue('carrierId') == "no") {
        this.setItemValue('carrierId', null)
      }
      if(this.getFormItemValue('driverId') == "no") {
        this.setItemValue('driverId', null)
      }
    }
  }
  clearFilter() {
    for(let key of this.formInputKeys) {
      if (key === 'vehicleTypes') {
        this.setItemValue(key, []);
        continue;
      }
      this.setItemValue(key, null);
    }
    this.needDispatcherChecked = false;
    this.needCarrierChecked = false;
    this.needDriverChecked = false;
    this.needPODChecked = false;
    this.isWalmartScheduledLessThanDuration = false;
    this.pickLocationData = {};
    this.dropLocationData = {};
    this.isClearingEvent = true;
    this.includeCrossdock = false;
  }
  // reset form filter
  onBtnResetFilter() {
    this.clearFilter();
    this.onResetFilter();
  }

  public onApply: (dataFormJson, dataCheckBox) => void = () => { }
  public onResetFilter:() => void = () => {}

  onBtnFilters() {
    this.onApply(
      this.getFormData_JSON(true),
      {
        needDispatcherChecked: this.needDispatcherChecked,
        needCarrierChecked: this.needCarrierChecked,
        needPODChecked: this.needPODChecked,
        needDriverChecked:this.needDriverChecked,
        isWalmartScheduledLessThanDuration: this.isWalmartScheduledLessThanDuration,
        includeCrossdock: this.includeCrossdock
      }
    );
    this.closeDialog();
  }

  setShowDispatcher() {
    if(this.urlMyLoad == 'my_load') {
      return 'my-load'
    } else {
      return 'all-load'
    }
  }

  /**
   * Need Driver
   */
  public needDriverChecked = false;
  onCheckboxNeedDriver(event) {
    this.needPODChecked = false;
    if(event == true) {
      this.setItemValue('driverId', 'no')
    } else {
      this.setItemValue('driverId', null)
    }
  }

  public delayOTPChecked: boolean = this.getItemValue('delayOTP') || false;
  onCheckboxDelayOTP(event) {
    if(event == true) {
      this.setItemValue('delayOTP', true)
    } else {
      this.setItemValue('delayOTP', null)
    }
  }

  public delayOTDChecked: boolean = this.getItemValue('delayOTD') || false;
  onCheckboxDelayOTD(event) {
    if(event == true) {
      this.setItemValue('delayOTD', true)
    } else {
      this.setItemValue('delayOTD', null)
    }
  }

  public needDelayCodeChecked: boolean = this.getItemValue('needReasonCode') || false;
  onCheckboxNeedDelayCode(event) {
    if(event == true) {
      this.setItemValue('needReasonCode', true)
    } else {
      this.setItemValue('needReasonCode', null)
    }
  }

  public isWalmartScheduledLessThanDuration = false;
  onCheckboxWalmartFilter(event) {
    if (event) {
      this.setItemValue('clientId', ['01H22NK3MBXBDCW6AZDWFZ09V0']);
    } else {
      this.setItemValue('clientId', []);
    }
  }

  public includeCrossdock = false;
  onCheckboxIncludeCrossdock(event) {
    if(event == true) {
      this.setItemValue('includeCrossdock', true)
    } else {
      this.setItemValue('includeCrossdock', null)
    }
  }
  onBtnSaveFilter() {
    const formValue = this.getFormData_JSON(true);
    if (!formValue.name) {
      this.formInput.controls?.name?.addValidators(Validators.required);
      this.formInput.controls?.name?.markAsDirty();
      this.formInput.controls?.name?.updateValueAndValidity();
      return;
    }
    const _data = {
      ...formValue,
      needDispatcherChecked: this.needDispatcherChecked,
      needCarrierChecked: this.needCarrierChecked,
      needPODChecked: this.needPODChecked,
      needDriverChecked:this.needDriverChecked,
      isWalmartScheduledLessThanDuration: this.isWalmartScheduledLessThanDuration,
      includeCrossdock: this.includeCrossdock
    }
    if (this.filterInfo?.id) {
     _data.filterId = this.filterInfo.id;
    }
    this.onSave?.(_data, () => this.closeDialog());
  }

  onChangePreset(value) {
    const preset = parseInt(value);
    if (Number.isInteger(preset)) {
      this.formInput.get('fromDate').setValue(dayjs().toDate());
      this.formInput.get('toDate').setValue(dayjs().add(preset, 'day').toDate());
    }
  }

  get isShowWalmartClients() {
    if (this.getItemValue('clientId')?.length && this.getItemValue('clientId').includes("01H22NK3MBXBDCW6AZDWFZ09V0") && this.getItemValue('clientFilterType') == "include") return true;
    return false;
  }

  onLocationChangeValue(key: string, input: any) {
    const value = input.value;
    this.isClearingEvent = false;
    switch  (key) {
      case 'pickLocation':
        if (value?.locationType == "customize") {
          this.setItemValue('pickupWarehouseId', null);
          this.setItemValue('pickupState', value?.state);
          this.setItemValue('pickupZipcode', value?.zipcode);
        } else {
          this.setItemValue('pickupWarehouseId', value?.warehouseId);
          this.setItemValue('pickupState', null);
          this.setItemValue('pickupZipcode', null);
        }
        break
      case 'dropLocation':
        if (value?.locationType == "customize") {
          this.setItemValue('dropoffWarehouseId', null);
          this.setItemValue('dropoffState', value?.state);
          this.setItemValue('dropoffZipcode', value?.zipcode);
        } else {
          this.setItemValue('dropoffWarehouseId', value?.warehouseId);
          this.setItemValue('dropoffState', null);
          this.setItemValue('dropoffZipcode', null);
        }
        break;
    }
  }
}
