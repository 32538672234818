import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms/forms";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { InputHelper } from "@services/input-helper";

@Component({
  selector: '[start-route-settings]',
  templateUrl: './index.html',
  styleUrls: ['../../../../dialogs/dialogs.scss', '../../../../../styles/form-v2.scss']
})
export class StartRouteSettings extends BaseFormDialog1 {
  public static get declaration(): FormGroupDeclaration {return {
    hours: {label: 'Hours', type: 'number', required: true},
  }}
  protected formGroupDeclaration: FormGroupDeclaration = StartRouteSettings.declaration;

  protected beforeBindModel(model: any) {
    let data = {}
    if (model?.hours) {
      data['hours'] = model?.hours;
    }
    return data;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public onSave: (data) => void = () => {}

  onBtnSave() {
    let json = this.formData_JSON(true);
    this.onSave(json);
    this.closeDialog();
  }

  onBtnCancel() {
    this.closeDialog();
  }

  onInputKeyPress(event, key) {
      switch (key) {
        case 'hours':
          return InputHelper.handleInputKeyPressNumberOnly(event);
        default:
          return super.onInputKeyPress(event, key);
      }
    }
  
    onInputChanged(event, key) {
      switch (key) {
        case 'hours':
          return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key));
        default:
          return super.onInputChanged(event, key);
      }
    }
}
  