<ng-container *ngIf="!driver?.id">
  <nz-empty [nzNotFoundContent]="'No Driver Assigned'"></nz-empty>
</ng-container>
<ng-container [ngTemplateOutlet]="driverMesForm" [ngTemplateOutletContext]="{onSubmit: onSubmit}" *ngIf="driver?.id"></ng-container>

<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

<div class="driver-mes-list" *ngIf="!isLoading">
  <div class="group-date bottom20" *ngFor="let date of getDateItems()">
    <div class="date-label">{{date}}</div>
    <div class="group-content">
      <ng-container *ngFor="let item of items[date]" [ngTemplateOutlet]="driverMesItem"
        [ngTemplateOutletContext]="{item}"></ng-container>
    </div>
  </div>
</div>

<ng-template #driverMesForm let-onSubmit="onSubmit">
  <div class="create-form">
    <!-- <nz-radio-group [(ngModel)]="type" nzButtonStyle="solid"> -->
      <!-- <label nz-radio-button nzValue="all" nzDisabled><span>Email & SMS</span></label> -->
      <!-- <label nz-radio-button nzValue="email" nzDisabled><span>Email</span></label> -->
      <!-- <label nz-radio-button nzValue="sms"><span>SMS</span></label> -->
    <!-- </nz-radio-group> -->
    <textarea nz-input class="input-driver-mes" placeholder="Send Message" [(ngModel)]="newMsg"
      [nzAutosize]="{ minRows: 2, maxRows: 6 }" [disabled]="isLoading"></textarea>
    <nz-space nzSize="small" class="driver-mes-button-group">
      <i nz-icon nzType="reload" nzTheme="outline" nz-tooltip nzTooltipTitle="Refresh" class="clickable left5" (click)="fetchData()"></i>
      <button *nzSpaceItem nz-button nzType="primary" (click)="onSubmit()" [disabled]="!shouldShowBtnSubmit">Submit</button>
    </nz-space>
  </div>
</ng-template>

<ng-template #driverMesItem let-item="item">
  <div class="driver-mes-list-item">
    <div class="item-header">
      <div class="poster">
        <div>
          <nz-avatar [nzText]="getFullName(item.user || item.driver).slice(0,1)" [ngStyle]="{ 'background-color': '#7265e6', 'min-width': '32px' }"></nz-avatar>
        </div>
        <div class="info">
          <div class="name semibold">{{getFullName(item.user || item.driver) || 'No name'}}</div>
          <div class="time">{{item.time}}</div>
          <div class="top" style="width: 100%; margin-top: 5px;">
            <div style="line-height: 22px;word-wrap: break-word;" *ngIf="item.content" [innerText]="item.content"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-body top20">
      
    </div>
  </div>
</ng-template>
