<div class="flex-column" style="height: 100%;">
  <div class="flex-column" style="flex: 1">
    <div class="tasks">
      <div class="task-list">
        <div cdkDropListGroup>
          <div class="example-container">
            <div cdkDropList [cdkDropListData]="stops" class="list" (cdkDropListDropped)="drop($event)">
              <div class="edit-route-sequence-task-item" *ngFor="let item of stops; let index = index" cdkDrag
                [ngClass]="item.getType()">
                <div class="content" nz-tooltip="Drag to change the stop order." nzTooltipPlacement="right">
                  <div class="title">
                    <nz-tag [nzColor]="item.getType() == 'DROPOFF'? 'green': 'purple'">
                      Stop {{index+1}} - {{item.getType()}}
                    </nz-tag>
                    <nz-tag class="status {{item.getStatus()}}">
                      {{item.getStatus()}}
                    </nz-tag>
                  </div>
                  <div class="description">
                    <div class="address">
                      <b *ngIf="item.getLocationName()">{{item.getLocationName()}}<br /></b>
                      {{item.getAddressText()}}
                    </div>
                    <div>
                      {{item.getAppointmentText()}} - {{item.getTotalPalletsText()}}
                    </div>
                    <div class="invalidTask" *ngIf="!item.isValid()">
                      The position of the stop is not valid.
                      It may be because there is already some DROPOFF task before PICKUP. Please check again.
                    </div>
                  </div>
                </div>
                <div class="miles">
                  <nz-tag>
                    {{(item.getCost()?.distance / 1609.34).toFixed(2).toLocaleString()}} miles,
                    {{(item.getCost()?.time/ 3600).toFixed(2).toLocaleString()}} hours</nz-tag>
                </div>
                <div class="hide-dashed-line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>