<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>
<div *ngIf="isLoading && inConfirm" class="text-center bold">Email sending in progress. Please wait a moment...</div>

<div class="confirm-content" *ngIf="!isLoading && inConfirm">
  <div class="email-bol-popup">
    <div class="title">Email BOL</div>
    <div class="description">Recipients will receive an email with a link to download BOL.</div>
  </div>
  <div class="confirm-content">

    <div nz-row class="bottom15">
      <div nz-col nzSpan="24"><b>Sender:</b></div>
      <!-- <div nz-col nzSpan="24">{{authUserFullName}} / {{authUserEmail}}</div> -->
      <div nz-col nzSpan="24">dispatch&#64;wearewarp.com</div>
    </div>

    <div nz-row *ngIf="confirmData?.sendToCarrier" class="bottom15">
      <div nz-col nzSpan="24"><b>Carrier's Email Address:</b></div>
      <div nz-col nzSpan="24">{{confirmData?.carrierEmail}}</div>
    </div>

    <div nz-row *ngIf="confirmData?.sendToPickupStop && sendContacts.size > 0" class="bottom15">
      <div nz-col nzSpan="24"><b>Pickup Stop:</b></div>
      <div nz-col nzSpan="24" *ngFor="let sendContact of sendContacts">{{allContacts.get(sendContact).email}} - {{allContacts.get(sendContact).name}}</div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="24"><b>Remark:</b></div>
      <div nz-col nzSpan="24">{{confirmData?.remark}}</div>
    </div>
  </div>
</div>

<form *ngIf="!isLoading && !inConfirm" class="form-detail" [formGroup]="formInput" nz-form nzLayout="vertical">
  <div class="email-bol-popup">
    <div class="title">Email BOL</div>
    <div class="description">Recipients will receive an email with a link to download BOL.</div>
  </div>

  <div nz-row class="bottom10">
    <div nz-col nzSpan="24">
      <label nz-checkbox formControlName="sendToCarrier">
        <span>Carrier</span>
      </label>
    </div>
  </div>

  <nz-form-item *ngIf="hasSendTo('sendToCarrier')">
    <nz-form-label nzRequired nzFor="carrierEmail">Email Address</nz-form-label>
    <nz-form-control nzErrorTip="The input is not valid E-mail!">
      <nz-input-group [nzAddOnAfter]="editCarrierEmailBtnTemp">
        <input 
          nz-input formControlName="carrierEmail" 
          id="carrierEmail" [nzAutocomplete]="auto" 
        />
        <nz-autocomplete #auto>
          <ng-container *ngFor="let option of contacts">
            <nz-auto-option [nzValue]="option" [nzLabel]="option">
              {{ option }}
            </nz-auto-option>
          </ng-container>
        </nz-autocomplete>

      </nz-input-group>
      <ng-template #editCarrierEmailBtnTemp>
        <a (click)="onEditEmail('carrierEmail')">
          Edit
        </a>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <div nz-row class="bottom10">
    <div nz-col nzSpan="24">
      <label nz-checkbox formControlName="sendToPickupStop" (ngModelChange)="onCheckPUStop($event)" [nzIndeterminate]="indeterminate">
        <span>Pickup Stop</span>
      </label>
    </div>
  </div>
  <div *ngIf="hasSendTo('sendToPickupStop')">
    <ul class="pickup-list" *ngFor="let stop of stops; let i = index">
      <ng-container *ngIf="stop.getType() === 'PICKUP'">
        <li>
          Stop {{i+1}} - PU - {{stop.getLocationName()}} - {{stop.getAddressText()}}
        </li>
        <ul class="pickup-list" *ngFor="let task of stop.getTasks(); let j = index">
          <li>
            <div>
              <a target="_blank" [routerLink]="[routeAdminShipmentList, task.getShipment().getOrderId()]">{{task.getShipment()?.getCodeText()}}</a>
              <span *ngIf="task.getPrimaryContact()?.email" nz-checkbox class="left10" [ngModel]="sendContacts.has(task.getPrimaryContact()?.email)" [ngModelOptions]="{standalone: true}" (ngModelChange)="onSelectStop($event, task.getPrimaryContact()?.email)"></span>
            </div>
            <div *ngIf="task.getPrimaryContact()?.firstName || task.getPrimaryContact()?.fullName">Primary Contact: {{getFullName(task.getPrimaryContact())}}</div>
            <div *ngIf="task.getPrimaryContact()?.email">Email: {{task.getPrimaryContact()?.email}}</div>
            <div *ngIf="!task.getPrimaryContact()?.email">The contact email is missing - please update it on Shipment detail screen</div>
          </li>
        </ul>
      </ng-container>
    </ul>
  </div>
  <nz-form-item>
    <nz-form-label nzFor="remark">Remark</nz-form-label>
    <nz-form-control>
      <textarea nz-input formControlName="remark" id="remark" placeholder="Write a message..."></textarea>
    </nz-form-control>
  </nz-form-item>
</form>

<div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress && canClickOK" [canClickCancel]="!onProgress"
  [labelOK]="inConfirm? 'Confirm': 'Email BOL'" (onOK)="onBtnSave()" (onCancel)="onBtnCancel()"></div>
