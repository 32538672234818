import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { NzButtonModule } from "ng-zorro-antd/button";
import { DispatchCustomerNote } from './index';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzButtonModule,
  ],
  declarations: [
    DispatchCustomerNote
  ],
  exports: [
    DispatchCustomerNote
  ]
})
export class ModuleDispatchCustomerNote {}