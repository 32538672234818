import { Injectable } from "@angular/core";
import { ApiService } from "@services/api.service";
import { ApiUri } from "./const";
import { RequestAdminSetClientDispatchNote, ResponseAdminGetClientDispatchNote } from "@wearewarp/types-server-admin/client";

@Injectable({ providedIn: 'root' })
export class DataRepoClient {
  constructor(private api: ApiService) {}

  getDispatchNote(clientId: string): Promise<ResponseAdminGetClientDispatchNote> {
    const url = ApiUri.Client.dispatchNote(clientId);
    return this.api.get<ResponseAdminGetClientDispatchNote>(url).toPromise();
  }

  setDispatchNote(clientId: string, content: string): Promise<ResponseAdminGetClientDispatchNote> {
    const url = ApiUri.Client.dispatchNote(clientId);
    const data: RequestAdminSetClientDispatchNote = {content};
    return this.api.post<ResponseAdminGetClientDispatchNote>(url, data).toPromise();
  }
}