
<form nz-form *ngIf="formInput" [formGroup]="formInput" class="form-detail" nzLayout="vertical">
  <!--  Shipment Detail-->
  <nz-row>
    <nz-col [nzMd]="24">
      <div class="form-container">
        <div *ngFor="let key of ['responsiveness', 'collaboration', 'techSavvy', 'recommendationLikelihood', 'customerSatisfaction']" class="form-item">
          <div style="margin-bottom: 15px;">
            <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
              class="label-mark-required"></span>
            </div>
            <nz-rate [formControlName]="key"></nz-rate>
            <ng-container *ngIf="getValue(key)">
              <span class="ant-rate-text">{{ getValue(key)}}/5</span>
            </ng-container>
          </div>
        </div>
        <div *ngFor="let key of ['comment']" class="form-item">
          <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
            class="label-mark-required"></span>
          </div>
          <nz-form-item class="{{key}}">
            <nz-form-control>
              <textarea nz-input [formControlName]="key" [placeholder]="getPlaceHolder(key)" [nzAutosize]="{minRows: 2, maxRows: 4}"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- <div class="rate">
          <div class="item flex">
            <div app-rating
              [titleHeader]="'Rate this route'"
              [model]="rating"
              [isShowLabel]="true"
              [thumpUpOptions]="thumupCarrierRateOptions"
              [thumpDownOptions]="thumdownCarrierRateOptions"
              [onSubmit]="onSubmitLikeDisLike"
              (onDone)="refreshService()">
            </div>
          </div>
        </div> -->
      </div>
    </nz-col>
  </nz-row>
</form>