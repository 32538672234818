import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { NzTimelineMode } from 'ng-zorro-antd/timeline';
import { DateUtil } from "@services/date-utils";
import { DialogService } from "@dialogs/dialog.service";
import { HistoryDetail } from "./history-detail";
import { BaseComponent } from "@abstract/BaseComponent";
import dayjs from "dayjs"
import timezone from 'dayjs/plugin/timezone';
import { DispatchService } from "../../dispatchService";
import { Subscription } from "rxjs";
import { Job } from "@wearewarp/types/data-model";
dayjs.extend(timezone)

@Component({
  selector: 'history-list',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class HistoryList extends BaseComponent {
  private prefix_title = {
    "orders": "Changes of Order ",
    "jobs": "Changes of Route ",
    "carrier_bids": "Changes of Carrier Bid ",
    "job_notes": "Changes of notes  in Route ",
    "users": "Changes of User ",
    "clients": "Changes of Client ",
    "drivers": "Changes of Driver ",
    "warehouses": "Changes of Location ",
    "carriers": "Changes of Carrier ",
    "shipments": "Changes of Shipment ",
  }
  @Input() entity: string;
  private _objectIds = [];
  @Input() get objectId() { return this._objectIds } ;
  @Input() isCustomTimeline: boolean = false;
  @Output() onGetHeader: EventEmitter<any> = new EventEmitter<any>();
  set objectId(value) {
    this._objectIds.push(value);
    if (value) {
      this.getDataLog();
    }
  }

  public listChange = [];
  public isLoading = false;
  mode: NzTimelineMode = 'left'; //alternate

  constructor(private dispatchService: DispatchService) {
    super();
  }

  ngOnInit(): void {
    // Lắng nghe sự kiện từ service
    this.dispatchService.routeData$.subscribe((data: any) => {
      console.log('Route data updated!', data.id, data.__id);
      if(data.id !== data.__id) {
        this._objectIds = [data.id, data.__id];
        this.getDataLog();
      }
    });
    this.subscription = this.dispatchService.updateLog$.subscribe(() => {
      console.log('Log updated after assigning driver!');
      this.getDataLog();  // Gọi lại hàm getDataLog khi sự kiện được phát
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getDataLog() {
    this.isLoading = true;    
    const urls = this._objectIds.map(objectId => `${Const.APIURI_AUDIT_LOGS}?objectId=${objectId}&entity=${this.entity}`);
    this.api.concurrentGET(urls).subscribe(
      resp => {
        const data = resp.map(item => item.data?.list_data || []).flat();
        this.listChange = data.sort(function(a,b){
          let bDate = new Date(b.eventTime)
          let aDate = new Date(a.eventTime)
          return Number(bDate) - Number(aDate)
        });
        this.isLoading = false;
        const header = this.prefix_title[this.entity] +  resp?.[0]?.data?.title
        this.onGetHeader.emit(header);
      }, err => {
        this.isLoading = false;
        this.showErr(err);
      }
    );
  }

  getDetailById(id){
    let detail = this.listChange.find(item => item._id === id)
    if(!detail) return []
    let detailMessage = detail.detailMessage || {}
    let result = this.convertObjectToTree(detailMessage)
    return result;
  }
  
  convertObjectToTree(data, path = ""){
    let result = []
    if(this.isValue(data)){
      return data != null? data : null
    }
    if(this.isArrayValue(data)){
      return data.join(", ")
    }
    
    const keys = Object.keys(data)
    
    for(let key of keys){
      let node={} as any
      node.title = key;
      node.expanded = true;
      let childPath = path? `${path}.${key}`: key
      let value = this.convertObjectToTree(data[key], childPath)
      //gán thuộc tính phục vụ cho css
      if(key === "Old value" || key === "Old Value"){
        node.isOld = true
      }
      
      if(key === "New value" || key === "New Value"){
        node.isNew = true
      }
      //nếu null thì kg hiện UI => tránh rác
      if(!["Old value", "New value"].includes(key) && value == null){
        continue
      }
      if(this.isValue(value)){
        if(this.isIsoDate(value)){
          let timezone = dayjs.tz.guess();
          let tzShore = DateUtil.timezoneStandardToUsShort(timezone) || timezone
          value = DateUtil.displayLocalTime(value, {timezone, format: Const.FORMAT_GUI_DATETIME_V3})  + ` (${tzShore})`;
        }
        node.title = `${key}: ${(value == null ||  value == "")? "[NO DATA]" : this.preformatData(value, path)}`
        node.isLeaf = true 
      } else {
        node.children = value
      } 
      
      result.push(node);
    }
    return result.length? result : null;
  }

  preformatData(value, path= ""){
    path = path.replace(".Old value", "")
    path = path.replace(".New value", "")
    if(path.startsWith("Status") && this.entity==="carriers"){
      return this.getStatusCarrier(value)
    }
    return value;
  }

  isIsoDate(str){
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const d = new Date(str); 
    return d instanceof Date && !isNaN(d as any) && d.toISOString()===str; // valid date 
  }
  
  isValue(x){
    return (!this.isObject(x) && !this.isArray(x)) || x == null || x instanceof Date
  }
  isObject(variable) {
    return (typeof variable === 'object' || variable instanceof Object) && !(variable instanceof Date);
  }
  
  isArray(variable) {
    return Array.isArray(variable);
  }
  
  isArrayValue (data: any){ 
    if(!Array.isArray(data)){
      return false
    };
    for(let item of data){
      if(!this.isValue(item)){
        return false;
      }
    };
    return true
  }

  formatDate(time: string) {
    return DateUtil.format(time, Const.DATETIME_FORMAT_FROM_DB);
  }
  
  private hilightItemId: string | undefined;

  shouldHilightItem(id): boolean {
    return !!this.hilightItemId && this.hilightItemId == id;
  }

  openDetail(id) {
    const afterOpen = new EventEmitter();
    afterOpen.subscribe(() => {
      this.hilightItemId = id;
    });
    const afterClose = new EventEmitter();
    afterClose.subscribe(() => {
      this.hilightItemId = undefined;
    });
    DialogService.openDialog(HistoryDetail, {
      nzComponentParams: {
        data: this.getDetailById(id)
      },
      nzClassName: 'modal-no-padding',
      nzCentered: true,
      nzWidth: 800,
      nzAfterOpen: afterOpen,
      nzAfterClose: afterClose,
    });
  }
}